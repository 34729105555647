import { Injectable } from '@angular/core';
import { QueryTableDatasource, QueryTableDatasourceException } from '@nunc/lib/components';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { UserFilter } from '@yukawa/chain-main-angular-core';
import { lastValueFrom } from 'rxjs';
import { UserTableEntry } from './user-table-entry.model';
import { User } from './user.model';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root',
})
export class UserDatasource extends QueryTableDatasource<User, UserFilter>
{
    constructor()
    {
        super();
    }

    public async read(filter?: UserFilter): Promise<Array<User>>
    {
        this.query = await lastValueFrom(AppInjector.get(UserService).queryUser({
            orderBy          : 'userId',
            orderDir         : 'DESC',
            withGroupContexts: true,
            ...filter,
        }));
        return this.query.items;
    }

    public newEntry(item?: User): UserTableEntry
    {
        const entry = new UserTableEntry(item);
        entry.init();
        return entry;
    }

    public async create(entry: UserTableEntry): Promise<UserTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(UserService).saveUser({
            ...entry.entity,
            username: entry.entity.person.email,
        })));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: UserTableEntry, user?: Partial<User>): Promise<UserTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`User file ${entry.id} does not exist.`);
        }

        if (user) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(UserService).saveUser({
                ...user,
                username: entry.entity.username,
            } as User, 'put')) as User);

            if (user.account?.credentials) {
                await lastValueFrom(AppInjector.get(UserService).changePassword(user.account.credentials));
            }
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: UserTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`User file ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(UserService).deleteUser(entry.entity.username));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(username: string): Promise<UserTableEntry>
    {
        return this.newEntry((await lastValueFrom(AppInjector.get(UserService).queryUser({
            username,
            withGroupContexts: true,
        }))).items[0]);
    }
}
