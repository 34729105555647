<ng-container [ngSwitch]="style">
    <button mat-icon-button
            *ngSwitchCase="'close'"
            (click)="routerLink === undefined && close()"
            [routerLink]="routerLink"
            class="close w-10 h-8 z-10 rounded-full flex"
            title="{{'BUTTON.CLOSE' | transloco }}">
        <mat-icon [svgIcon]="closeSvgIcon" class="text-white"></mat-icon>
    </button>
    <button mat-icon-button
            *ngSwitchCase="'back'"
            [routerLink]="routerLink"
            title="{{'BUTTON.BACK' | transloco }}"
            (click)="routerLink === undefined && close()">
        <mat-icon [svgIcon]="backSvgIcon" class="text-black"></mat-icon>
    </button>
</ng-container>
