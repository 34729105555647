import { Nullable, PlainObject } from 'simplytyped';


export class ElementPadding
{
    public readonly left: number;
    public readonly top: number;
    public readonly right: number;
    public readonly bottom: number;

    public constructor(element: HTMLElement = document.body)
    {
        this.left   = +(ElementPadding.getNumericValue(element, 'paddingLeft') as number);
        this.top    = +(ElementPadding.getNumericValue(element, 'paddingTop') as number);
        this.right  = +(ElementPadding.getNumericValue(element, 'paddingRight') as number);
        this.bottom = +(ElementPadding.getNumericValue(element, 'paddingBottom') as number);
    }

    public get x(): number
    {
        return this.left + this.right;
    }

    public get y(): number
    {
        return this.top + this.bottom;
    }

    public static getNumericValue(element: HTMLElement, key: string): Nullable<number>
    {
        const match = (window.getComputedStyle(element) as PlainObject)[key].match(/\d+/g);
        return match ? Number(match[0]) : null;
    }
}
