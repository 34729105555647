import { MatDateFormats } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppInjector } from '@yukawa/chain-base-angular-client';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/

const getDateFormat = (format: string): string => AppInjector.get(TranslocoService).translate('FORMAT.DATE.' + format);

export const MOMENT_FORMATS: MatDateFormats = {
    parse  : new class
    {
        get dateInput(): string
        {
            return getDateFormat('SHORT_DATE');
        }
    }(),
    display: new class
    {
        get dateInput(): string
        {
            return getDateFormat('SHORT_DATE');
        }

        get monthYearLabel(): string
        {
            return getDateFormat('MONTH_YEAR');
        }

        get dateA11yLabel(): string
        {
            return getDateFormat('SHORT_DATE');
        }

        get monthYearA11yLabel(): string
        {
            return getDateFormat('MONTH_YEAR_ALLY');
        }
    }(),
};
