import { Directive, ElementRef, EventEmitter, HostListener } from '@angular/core';


/**
 * Directive for detecting element focused state.
 *
 * @example
 * referencing the directive:
 * ```
 *     <input focused #isFocused="hasFocus">
 *     <div>has focus: {{isFocused.hasFocus}}</div>
 * ```
 * binding to the event:
 * ```
 *     <input focused (focusChange)="isFocused = $event">
 *     <div>has focus: {{isFocused}}</div>
 * ```
 */
@Directive({
    selector: 'input[focused],textarea[focused]',
    exportAs: 'hasFocus',
})
export class FocusedDirective
{
    hasFocus: boolean                  = false;
    focusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private el: ElementRef)
    {
    }

    @HostListener('blur', ['$event'])
    onBlur(e: FocusEvent): void
    {
        this.hasFocus = false;
        this.focusChange.emit(this.hasFocus);
    }

    @HostListener('focus', ['$event'])
    onFocus(e: FocusEvent): void
    {
        this.hasFocus = true;
        this.focusChange.emit(this.hasFocus);
    }
}
