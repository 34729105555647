import { NgModule } from '@angular/core';

import { YukawaConsoleLogAlertsModule } from '@yukawa/chain-base-angular-comp/console-log-alerts';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { EnterpriseLayoutModule } from 'app/layout/layouts/horizontal/enterprise/enterprise.module';
import { SharedModule } from 'app/shared/shared.module';


const layoutModules = [

    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    EnterpriseLayoutModule,
];

@NgModule({
    declarations: [
        LayoutComponent,
    ],
    imports     : [
        SharedModule,
        SettingsModule,
        ...layoutModules,
        YukawaConsoleLogAlertsModule,
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules,
    ],
})
export class LayoutModule
{
}
