import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';


@Pipe({
    name: 'likes',
})
export class LikesPipe implements PipeTransform
{
    constructor(
        private _translocoService: TranslocoService,
    )
    {
    }

    transform(value: number, ...args: unknown[]): string | undefined
    {
        if (value == null) {
            return;
        }

        let translateKey: string;
        if (value === 1) {
            translateKey = 'VIDEO.COMMENT_LIKES.SINGLE';
        }
        else if (value < 1000) {
            translateKey = 'VIDEO.COMMENT_LIKES.BELOW_1K';
        }
        else if (value < 1000000) {
            translateKey = 'VIDEO.COMMENT_LIKES.BELOW_MIL';
            value        = value / 1000;
        }
        else {
            translateKey = 'VIDEO.COMMENT_LIKES.BELOW_MRD';
            value        = value / 1000000;
        }
        return this._translocoService.translate(
            translateKey,
            { count: value.toLocaleString(this._translocoService.getActiveLang()) },
        );
    }
}
