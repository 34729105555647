import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatusIndicatorComponent } from './status-indicator.component';


@NgModule({
    declarations: [
        StatusIndicatorComponent,
    ],
    imports     : [
        CommonModule,
    ],
    exports     : [
        StatusIndicatorComponent,
    ],
})
export class StatusIndicatorModule
{

}
