import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { fadeIn } from '@nunc/lib/animations';
import { EmojiService, getPaginatorIntl, MarkedPipe } from '@nunc/lib/shared';
import { ConfigService, isNumber, TemplateString } from '@yukawa/chain-base-angular-client';
import { Pager, TableFilter } from '@yukawa/chain-base-angular-domain';
import { cloneDeep, isEqual } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { ConstructorFor, Nullable, PlainObject } from 'simplytyped';
import { QueryTableStore, Sort } from './model';
import { IQueryTableEntry, IQueryTableEntryComponent, IQueryTableEntryDetail, IQueryTableEntryDetailComponent } from './types';


export interface Action<T extends string = string>
{
    type: 'icon' | 'link' | 'button';
    icon?: string;
    name: T;
    available?: (row: IQueryTableEntry<any>) => boolean;
}

export interface EntryAction<T extends IQueryTableEntry = IQueryTableEntry>
{
    action: string;
    row: T;
}

@Component({
    selector   : 'lib-query-table',
    templateUrl: './query-table.component.html',
    styleUrls  : ['./query-table.component.scss'],
    animations : [fadeIn],
    providers  : [
        {
            provide   : MatPaginatorIntl,
            useFactory: getPaginatorIntl,
        },
    ],
})
export class QueryTableComponent<T extends IQueryTableEntry = IQueryTableEntry> implements OnInit, OnChanges, OnDestroy
{
    @ViewChild(MatPaginator, { static: true })
    paginator!: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort!: MatSort;

    @ViewChildren('matRow', { read: ViewContainerRef })
    containers!: QueryList<ViewContainerRef>;

    @Input()
    filter!: TableFilter;

    @Input()
    dataSource!: QueryTableStore<T>;

    @Input()
    dataStore!: ConstructorFor<QueryTableStore<T>>;

    @Input()
    defaultSort!: Sort<T>;

    @Input()
    pageSize!: number;

    @Input()
    reloadEntry!: EventEmitter<T>;

    @Input()
    selectedEntryComponent: Nullable<ConstructorFor<IQueryTableEntryComponent>>;

    @Input()
    cellComponents: Nullable<Map<string, ConstructorFor<IQueryTableEntryDetailComponent>>>;

    @Input()
    displayedColumns: string[] = [];

    @Input()
    displayPager: boolean = true;

    @Input()
    displayActions: boolean = true;

    @Input()
    actions = new Array<Action>();

    @Input()
    paginatorPosition: 'top' | 'bottom' = 'top';

    @Input()
    rowSelect: boolean = true;

    @Input()
    expandOnEntrySelect: boolean = false;

    @Input()
    expandOnEntrySelectCollapsedTitle: string = '';

    @Input()
    expandOnEntrySelectExpandedTitle: string = '';

    @Input()
    queryParams!: PlainObject;

    @Input()
    applyFilter: Nullable<(params: ParamMap) => void | Promise<void>>;

    @Input()
    allowNavigation: boolean = true;

    @Output()
    entryAction = new EventEmitter<EntryAction<T>>();

    @Output()
    readonly entrySelected = new EventEmitter<Nullable<T>>();

    expandedRow: Nullable<number> = null;

    private readonly _markedPipe = new MarkedPipe();

    private _skipParams!: boolean;

    readonly #defaultPageSizeKey: string;
    readonly #unsubscribeAll = new Subject();

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _configService: ConfigService,
        private readonly _router: Router,
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _emojiService: EmojiService,
    )
    {
        this.#defaultPageSizeKey = TemplateString`${'store'}=${'item'}`({
            store: this._configService.getValue('sessionStoreKey'),
            item : this._configService.getValue('sessionStorePrefix') + 'defaultPageSize',
        });
    }

    get defaultPageSize(): number
    {
        return localStorage.getItem(this.#defaultPageSizeKey)
            ? Number(localStorage.getItem(this.#defaultPageSizeKey))
            : 10;
    }

    set defaultPageSize(value: number)
    {
        localStorage.setItem(this.#defaultPageSizeKey, String(value));
    }

    get skipParams(): boolean
    {
        return this._skipParams;
    }

    async ngOnInit(): Promise<void>
    {
        if (this.dataSource?.dispose) {
            this.dataSource?.dispose();
        }
        this.dataSource = new this.dataStore(this.paginator, this.sort, this.filter, ...this.displayedColumns || []);
        if (this.selectedEntryComponent) {
            this.dataSource.actionColumns.push({ name: 'expand', index: 0 });
        }
        if (this.displayActions && this.actions.length > 0) {
            this.dataSource.actionColumns.push({ name: 'actions' });
        }

        if (this.filter?.pager) {
            this.paginator.pageSize  = this.filter.pager.pageSize;
            this.paginator.pageIndex = this.filter.pager.firstResult / this.filter.pager.pageSize;
        }
        else {
            this.paginator.pageSize = this.pageSize || this.defaultPageSize;
        }
        if (this.filter?.orderBy) {
            this.sort.sort({
                id          : this.filter.orderBy as string,
                start       : this.filter.orderDir?.toLowerCase() as never,
                disableClear: false,
            });
        }
        this.dataSource.entrySelected.asObservable()
            .pipe(takeUntil(this.#unsubscribeAll))
            .subscribe((entry) =>
            {
                this.onEntrySelected(entry);
            });

        this.dataSource.loaded.asObservable()
            .pipe(takeUntil(this.#unsubscribeAll))
            .subscribe(async (users: Array<IQueryTableEntry>) =>
            {
                if (this.filter.pager) {
                    this.defaultPageSize = this.filter.pager.pageSize;
                }
                await this.navigate(this.queryParams, true);
            });

        this._activatedRoute.queryParamMap
            .pipe(takeUntil(this.#unsubscribeAll))
            .subscribe(async (params) =>
                {
                    if (this._skipParams) {
                        return;
                    }

                    const filter = cloneDeep(this.dataSource.filterSubject.getValue());

                    if (params.has('pageSize')) {
                        this.defaultPageSize = Number(params.get('pageSize'));
                        if (!this.filter.pager) {
                            this.filter.pager = {
                                pageSize: this.defaultPageSize,
                            } as Pager;
                        }
                        else {
                            this.filter.pager.pageSize = this.defaultPageSize;
                        }
                    }
                    else {
                        delete this.filter['pager'];
                    }

                    if (params.has('firstResult')) {
                        if (!this.filter.pager) {
                            this.filter.pager = {
                                firstResult: Number(params.get('firstResult')),
                            } as Pager;
                        }
                        else {
                            this.filter.pager.firstResult = Number(params.get('firstResult'));
                        }
                    }
                    else {
                        delete this.filter['pager'];
                    }

                    if (params.has('orderBy')) {
                        this.filter.orderBy = params.get('orderBy') as string;
                    }
                    else if (this.filter.orderBy) {
                        delete this.filter['orderBy'];
                    }
                    if (params.has('orderDir')) {
                        this.filter.orderDir = params.get('orderDir') as string;
                    }
                    else if (this.filter.orderDir) {
                        delete this.filter['orderDir'];
                    }

                    if (this.applyFilter) {
                        await this.applyFilter(params);
                    }

                    const lastStatus = this.dataSource.status;
                    this.applyFilterQuery();

                    // Perform initial loading if datasource status was 'loading' before filter applied (on component init).
                    if (lastStatus === 'loading') {
                        this.dataSource.load();
                    }

                    // Reload datasource if filter changed.
                    if (this.dataSource.status === 'ready' && !isEqual(
                        filter,
                        this.dataSource.filterSubject.getValue(),
                    )) {
                        this.reload();
                    }
                },
            );
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void>
    {
        if (changes['dataSource']?.previousValue) {
            this.dataSource.disconnect();
            this._changeDetectorRef.detectChanges();
            await this.ngOnInit();
        }

        if (!changes['reloadEntry']?.previousValue) {
            this.reloadEntry
                ?.pipe(takeUntil(this.#unsubscribeAll))
                .subscribe(() =>
                {
                    this.dataSource.reload();
                });
        }
    }

    ngOnDestroy(): void
    {
        if (this.dataSource?.dispose) {
            this.dataSource.dispose();
        }
        this.#unsubscribeAll.next(null);
        this.#unsubscribeAll.complete();
    }

    getRowDetail(row: T, detail: IQueryTableEntryDetail): any
    {
        return this.dataSource.getRowDetail<string>(row, detail, (value: string) =>
        {
            if (detail?.emojiSupport) {
                value = this._emojiService.colonsToNative(value);
            }
            if (detail?.markdownSupport) {
                value = this._markedPipe.transform(value) as string;
            }
            if (Array.isArray(value)) {
                value = value.join(', ');
            }
            return value;
        });
    }

    public async onSortChanged($event: Sort): Promise<void>
    {
        setTimeout(async () =>
        {
            if ($event.direction === '') {
                await this.navigate({}, true);
            }
        });
    }

    public async navigate(params?: {
        pageSize?: number;
        firstResult?: number;
        orderBy?: string;
        orderDir?: string;
    } & PlainObject, skipParams = false): Promise<boolean>
    {
        if (skipParams) {
            this._skipParams = true;
        }

        if (this.filter.pager?.firstResult === 0) {
            this.paginator.pageIndex = 0;
        }

        if (!this.allowNavigation) {
            return Promise.resolve(false);
        }

        return await this._router.navigate(
            [],
            {
                relativeTo         : this._activatedRoute,
                queryParams        : {
                    pageSize   : this.filter.pager?.pageSize,
                    firstResult: this.filter.pager?.firstResult,
                    orderBy    : this.filter.orderBy,
                    orderDir   : this.filter.orderDir,
                    ...params,
                },
                queryParamsHandling: 'merge',
                replaceUrl         : this._router.url.indexOf('pageSize') === -1,
            },
        ).finally(() =>
        {
            if (skipParams) {
                this._skipParams = false;
            }
        });
    }

    reload(): void
    {
        this.dataSource.reload();
    }

    async expandButtonClick($event: MouseEvent, row: T): Promise<void>
    {
        if (!this.expandOnEntrySelect) {
            $event.stopPropagation();
            const index = this.dataSource.entries.indexOf(row);
            await this.expandRow(index === this.expandedRow ? null : index);
        }
    }

    rowIsExpanded(row: T): boolean
    {
        return isNumber(this.expandedRow) && this.expandedRow === this.dataSource.entries.indexOf(row);
    }

    async expandRow(index: Nullable<number>): Promise<void>
    {
        if (!this.selectedEntryComponent) {
            return;
        }
        if (index == null && this.expandedRow != null) {
            this.containers.toArray()[this.expandedRow]?.clear();
            this.expandedRow = null;
        }

        for (let i = 0; i < this.containers.length; i++) {
            const container = this.containers.toArray()[i];
            if (i === index) {
                if (container.length === 0) {
                    const expandedComponent = container.createComponent(this.selectedEntryComponent);

                    expandedComponent.instance.entry = this.dataSource.entries[index];
                    this.expandedRow                 = index;
                    const scroll = (timeout: number = 0): void =>
                    {
                        setTimeout(() =>
                        {
                            this._changeDetectorRef.detectChanges();
                            (container.element.nativeElement as HTMLElement).previousElementSibling?.scrollIntoView({
                                block   : 'start',
                                inline  : 'start',
                                behavior: 'smooth',
                            });
                        }, timeout);
                    };
                    scroll();
                    scroll(75);
                    scroll(125);
                    scroll(175);
                    scroll(225);
                }
            }
            else {
                container.clear();
            }
        }
    }

    public getCellComponent(key: string): ConstructorFor<IQueryTableEntryDetailComponent>
    {
        return this.cellComponents?.get(key) as ConstructorFor<IQueryTableEntryDetailComponent>;
    }

    public rowClicked(row: Nullable<T>): void
    {
        if (this.dataSource.selectedEntry === row) {
            row = null;
        }
        this.dataSource.selectedEntry = row;
    }

    public onEntryAction($event: MouseEvent, row: T, action: Action): void
    {
        $event.stopPropagation();
        this.entryAction.emit({
            action: action.name,
            row,
        });
    }

    protected applyFilterQuery(): void
    {
        if (this.filter.pager) {
            this.dataSource.paginator.pageSize  = this.filter.pager.pageSize;
            this.dataSource.paginator.pageIndex = this.filter.pager.firstResult / this.filter.pager.pageSize;
        }

        if (this.dataSource.sort.sortables.size === 0) {
            return;
        }

        if (this.filter.orderBy) {
            const sortable              = this.filter.orderBy.split('.')[0];
            this.dataSource.sort.active = this.dataSource.sort.sortables.has(sortable)
                ? sortable
                : this.filter.orderBy as string;
        }
        else if (this.dataSource.sort.active !== '') {
            this.dataSource.sort.active = '';
            this.dataSource.sort.sortChange.next({
                active   : this.filter.orderBy as string,
                direction: '' as never,
            });
        }

        if (this.filter.orderDir &&
            this.dataSource.sort.direction !== this.filter.orderDir.toLowerCase()) {
            this.dataSource.sort.direction = this.filter.orderDir.toLowerCase() as never;
            this.dataSource.sort.sortChange.next({
                active   : this.filter.orderBy as string,
                direction: this.filter.orderDir.toLowerCase() as never,
            });
        }
        //this.dataSource.filterSubject.next(this.filter);
    }

    protected async onEntrySelected(entry: Nullable<T>): Promise<void>
    {
        if (entry == null) {
            await this.navigate(undefined, true);
            this.entrySelected.emit(entry);
        }
        else {
            this._skipParams = true;
            this.entrySelected.emit(entry);
            this._skipParams = false;
        }

        if (this.expandOnEntrySelect) {
            await this.expandRow(entry ? this.dataSource.entries.indexOf(entry) : entry);
        }
    }
}
