import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { marked } from 'marked';


@Pipe({
    name: 'marked',
})
export class MarkedPipe implements PipeTransform
{
    // Set the options for the markdown renderer
    constructor()
    {
        marked.setOptions({
            renderer   : new marked.Renderer(),
            mangle     : true,
            pedantic   : false,
            gfm        : true,
            breaks     : true,
            smartLists : true,
            smartypants: false,
            xhtml      : false,
        });
    }

    transform(value: string, ...args: any[]): string | SafeValue
    {
        if (value && value.length > 0) {
            return AppInjector.get(DomSanitizer).bypassSecurityTrustHtml(marked(value));
        }
        return value;
    }
}
