import { ChainKey, Changed, Created, EntityFilter, Info, InfoFilter } from '@yukawa/chain-base-angular-domain';


export interface ImageBody
{
    data?: string;
    imageId?: number;
}

export interface Image extends Created, Changed
{
    category?: string;
    image?: ImageBody;
    imageId?: number;
    info?: Info;
    mime?: string;
    position?: number;
    related?: ChainKey;
    thumbnailLarge?: ImageBody;
    thumbnailMedium?: ImageBody;
    thumbnailSmall?: ImageBody;
}

export interface ImageFilter extends EntityFilter
{
    category?: string;
    fetchBody?: 'DEFAULT';
    imageId?: number;
    imageIds?: Array<number>;
    info?: InfoFilter;
    mime?: string;
    position?: number;
    related?: ChainKey;
    timeout?: number;
}

export enum DownloadSize
{
    default = 'DEFAULT',
    small   = 'SMALL',
    medium  = 'MEDIUM',
    large   = 'LARGE'
}

export interface DownloadParams
{
    category?: string;
    imageId?: number;
    position?: number;
    relatedEntity?: string;
    relatedId?: string;
    relatedModule?: string;
    size?: DownloadSize;
    timestamp?: number;
}
