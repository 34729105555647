import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@nunc/lib/shared';
import { NuncConfirmDialogComponent } from './confirm-dialog.component';


@NgModule({
    declarations   : [
        NuncConfirmDialogComponent,
    ],
    imports        : [
        MatDialogModule,
        MatButtonModule,
        SharedModule,
        TranslocoModule,
    ],
    entryComponents: [
        NuncConfirmDialogComponent,
    ],
})
export class NuncConfirmDialogModule
{
}
