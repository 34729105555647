/* tslint:disable:max-line-length */
import { NuncNavigationItem } from '@nunc/components/navigation';
import { Role, UserService } from '@nunc/lib/profile';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { environment } from 'environments';


const navigation = new Array<NuncNavigationItem>(
    {
        id     : 'dashboard',
        title  : 'Dashboard',
        type   : 'basic',
        icon   : 'heroicons_outline:chart-bar',
        classes: {
            icon: 'icon-size-5',
        },
        link   : '/dashboard',
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
    },
    {
        id     : 'device',
        title  : 'Device',
        type   : 'basic',
        icon   : 'coffee_maker',
        classes: {
            icon: 'icon-size-5',
        },
        link   : '/device',
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
    },
    {
        id     : 'firmware',
        title  : 'Firmware',
        type   : 'basic',
        icon   : 'heroicons_outline:chip',
        classes: {
            icon: 'icon-size-5',
        },
        link   : '/firmware',
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.firmwareEdit),
    },
    {
        id      : 'product',
        title   : 'Products',
        type: 'collapsable',
        icon    : 'mat_outline:coffee',
        classes : {
            icon: 'icon-size-5',
        },
        hidden  : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
        children: [
            {
                id     : 'roast',
                title  : 'Roasts',
                type   : 'basic',
                icon   : 'nunc:coffee',
                classes: {
                    icon: 'icon-size-5',
                },
                link   : '/product',
                hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
            },
            {
                id     : 'recipe',
                title  : 'Recipes',
                type   : 'basic',
                icon   : 'nunc:recipe',
                classes: {
                    icon: 'icon-size-5',
                },
                link   : '/recipe',
                hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
            },
            {
                id     : 'roaster',
                title  : 'Roasters',
                type   : 'basic',
                icon   : 'nunc:roaster',
                classes: {
                    icon: 'icon-size-6',
                },
                link   : '/roaster',
                hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
            },
            {
                id     : 'farmer',
                title  : 'Farmers',
                type   : 'basic',
                icon   : 'nunc:grinding',
                classes: {
                    icon: 'icon-size-6',
                },
                link   : '/farmer',
                hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
            },
        ],
    },
    {
        id     : 'order',
        title  : 'Orders',
        type   : 'basic',
        icon   : 'mat_outline:shopping_cart',
        classes: {
            icon: 'icon-size-5',
        },
        link   : '/order',
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
    },
    {
        id     : 'user',
        title  : 'Users',
        type   : 'basic',
        icon   : 'nunc:user-group',
        classes: {
            icon: 'icon-size-5',
        },
        link   : '/user',
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
    },
    {
        id     : 'lab',
        title  : 'Labs',
        type   : 'basic',
        icon   : 'nunc:lab',
        classes: {
            icon: 'icon-size-5',
        },
        link   : '/lab',
        hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
    },
    {
        id     : 'admin',
        title  : 'Admin',
        type    : 'collapsable',
        icon   : 'nunc:admin',
        classes: {
            icon: 'icon-size-5',
        },
        hidden  : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
        children: [
            {
                id     : 'admin',
                title  : 'Logs',
                type   : 'basic',
                icon   : 'nunc:recipe',
                classes: {
                    icon: 'icon-size-5',
                },
                link   : '/admin',
                hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
            },
            {
                id     : 'preset',
                title  : 'Presets',
                type   : 'basic',
                icon: 'nunc:admin',
                classes: {
                    icon: 'icon-size-5',
                },
                link   : '/preset',
                hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
            },
            {
                id     : 'grind-param',
                title  : 'Grind Params',
                type   : 'basic',
                icon   : 'nunc:admin',
                classes: {
                    icon: 'icon-size-5',
                },
                link   : '/grind-param',
                hidden : (): boolean => !AppInjector.get(UserService).hasRole(Role.admin),
            },
        ],
    },
);

if (!environment.production) {
    //navigation.push();
}

export const defaultNavigation: NuncNavigationItem[]    = navigation;
export const compactNavigation: NuncNavigationItem[]    = navigation;
export const futuristicNavigation: NuncNavigationItem[] = navigation;
export const horizontalNavigation: NuncNavigationItem[] = navigation;
