import { Nullable } from 'simplytyped';


/**
 * Returns the currently selected browser user language if contained
 * within the provided available languages or the provided default lang value.
 *
 * @param availableLanguages
 * @param defaultLang
 * @return
 */
export const getUserLanguage = <T extends string | Nullable<string>>(
    availableLanguages: Array<string>,
    defaultLang: T = 'en' as T): T =>
{
    const regExpExecArray = /([a-z]{2})(-[A-Z]{2})?/.exec(navigator.language);
    let language          = regExpExecArray && regExpExecArray.length > 1 ? regExpExecArray[1] : null;

    if (!language) {
        return defaultLang;
    }

    if (!availableLanguages.includes(language) && defaultLang) {
        language = defaultLang;
    }

    return language as T;
};
