import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';


@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform
{
    static _translationsInitialized = new Map<string, boolean>();

    constructor(
        private _translocoService: TranslocoService,
    )
    {
        const activeLang = _translocoService.getActiveLang();
        if (!DateFormatPipe._translationsInitialized.has(activeLang)) {
            moment.updateLocale(activeLang, {
                /* eslint-disable @typescript-eslint/naming-convention */
                longDateFormat: {
                    LT  : 'h:mm A',
                    LTS : 'h:mm:ss A',
                    L   : _translocoService.translate('FORMAT.DATE.SHORT_DATE', {}, activeLang),
                    LL  : 'MMMM Do YYYY',
                    LLL : 'MMMM Do YYYY LT',
                    LLLL: 'dddd, MMMM Do YYYY LT',
                },
                months        : [..._translocoService.translate('FORMAT.DATE.MONTHS', {}, activeLang).split('|')],
                monthsShort   : [..._translocoService.translate('FORMAT.DATE.MONTHS_SHORT', {}, activeLang).split('|')],
                weekdays      : [..._translocoService.translate('FORMAT.DATE.WEEKDAYS', {}, activeLang).split('|')],
                weekdaysShort : [
                    ..._translocoService.translate(
                        'FORMAT.DATE.WEEKDAYS_SHORT',
                        {},
                        activeLang,
                    ).split('|'),
                ],
                /* eslint-enable @typescript-eslint/naming-convention */
            });
            DateFormatPipe._translationsInitialized.set(activeLang, true);
        }
    }

    transform(value: moment.MomentInput, dateFormat: string): any
    {
        const date = moment(value);
        return date.isValid()
            ? date.locale(this._translocoService.getActiveLang()).format(dateFormat)
            : value;
    }
}
