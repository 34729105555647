import { Pipe, PipeTransform } from '@angular/core';
import { EmojiService } from '../service/emoji.service';


@Pipe({
    name: 'emoji',
})
export class EmojiPipe implements PipeTransform
{
    public constructor(
        private readonly _emojiService: EmojiService,
    )
    {
    }

    public transform(value: string, ...args: any[]): string
    {
        return value ? this._emojiService.colonsToNative(value) : value;
    }
}
