import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from '@awesome-nodes/object';
import { Version } from '@nunc/lib/config';


@Pipe({
    name: 'version',
})
export class VersionPipe implements PipeTransform
{
    // Set the options for the markdown renderer
    constructor()
    {
    }

    transform(value: string, ...args: any[]): string
    {
        if (value && isNumber(+value) || value.length > 0 && value.indexOf('.')) {
            try {
                value = `${new Version(isNumber(+value)
                    ? Number(value)
                    : value)}`;
            }
            catch {
            }
        }
        return value;
    }
}
