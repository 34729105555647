import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArgumentNullException } from '@awesome-nodes/object';
import { DownloadParams, Image as IImage, ImageFilter } from '@nunc/lib/domain';
import { ArgumentException, CachedRepoEntityService, ConfigService } from '@yukawa/chain-base-angular-client';
import { Info } from '@yukawa/chain-base-angular-domain';
import moment from 'moment';
import { Observable, switchMap } from 'rxjs';
import { Nullable, PlainObject } from 'simplytyped';
import { Image } from './image.entity';


@Injectable()
export class ImageService extends CachedRepoEntityService<Image, ImageFilter, number>
{
    constructor(
        http: HttpClient,
        configService: ConfigService)
    {
        super(
            http,
            configService,
            'imageUrl',
            (entity: Image) => entity.imageId,
        );
    }

    //region Convert file/data url

    /**
     * Read the given file for demonstration purposes
     *
     * @param file
     */
    readAsDataURL(file: File): Promise<string>
    {
        // Return a new promise
        return new Promise((resolve, reject) =>
        {

            // Create a new reader
            const reader = new FileReader();

            // Resolve the promise on success
            reader.onload = (): void =>
            {
                resolve(reader.result as string);
            };

            // Reject the promise on error
            reader.onerror = (e): void =>
            {
                reject(e);
            };

            // Read the file as the
            reader.readAsDataURL(file);
        });
    }

    public dataURItoBlob(dataURI: string): Blob
    {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        }
        else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        const ia         = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    //endregion

    public getImageMetaData(id: number | string, entity: string, info: Info = { name: entity }): IImage
    {
        return {
            category: 'main',
            related : {
                id    : String(id),
                entity,
                module: 'nunc',
            },
            info,
        };
    }

    /**
     * Add new File
     */
    upload(image: Image, file?: File): Observable<Image>
    {
        const formData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }

        const imageJson = image.toJson();
        delete imageJson['imageId'];
        const json = JSON.stringify(imageJson);
        const blob = new Blob([json], {
            type: 'application/json',
        });
        formData.append('image', blob);

        if (file) {
            const uploadObservable: Observable<Image> = this.repository.add(this.http.post<Image>(
                this.formatServiceUrl('/upload'),
                formData,
            ), image);

            if (image.imageId) {
                const deleteImage = new Image({ imageId: image.imageId });
                return this.delete(deleteImage).pipe(switchMap(() => uploadObservable));
            }
            else {
                return this.query({
                    category: image.category,
                    related : image.related,
                }).pipe(
                    switchMap((images) =>
                        {
                            if (images.items.length > 0) {
                                let observable: Nullable<Observable<Image>>;
                                for (const item of images.items) {
                                    if (!observable) {
                                        observable = this.delete(item);
                                    }
                                    else {
                                        observable = observable.pipe(
                                            switchMap(() => this.delete(item)),
                                        );
                                    }
                                }
                                return observable?.pipe(switchMap(() => uploadObservable)) || uploadObservable;
                            }
                            else {
                                return uploadObservable;
                            }
                        },
                    ));
            }
        }
        else {
            return this.repository.update(this.http.post<Image>(this.formatServiceUrl('/merge'), formData), image);
        }

    }

    public uploadImage(image: Image, file: File): Observable<Image>
    {
        if (!file) {
            throw new ArgumentNullException('', 'file');
        }

        // Return if the file is not allowed
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
            throw new ArgumentException(`Invalid mime type '${file.type}'.`);
        }

        if (image.imageId) {
            this.repository.remove(image.imageId);
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.repository.update(this.http.post<Image>(this.uploadUrl(image), formData), image);
    }

    public uploadUrl(image: Image): string
    {
        return image.toUrl(`${this.formatServiceUrl()}/uploadMainImageByRelated`).toString();
    }

    public uploadFile<T>(endpoint: string, file: File): Observable<T>
    {
        if (!endpoint) {
            throw new ArgumentNullException('', 'endpoint');
        }

        if (!file) {
            throw new ArgumentNullException('', 'file');
        }

        // Return if the file is not allowed
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
            throw new ArgumentException(`Invalid mime type '${file.type}'.`);
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<T>(endpoint, formData);
    }

    /**
     * Returns a browser cache resistant download url for the specified image.
     *
     * @param image
     * @param params
     */
    public downloadUrl(image: Image, params?: DownloadParams): string;
    public downloadUrl(params?: DownloadParams): string;
    public downloadUrl(image: Image | DownloadParams, params?: DownloadParams): string
    {
        const serviceUrl = this.formatServiceUrl('/download');

        let downloadUrl: string;

        if (image instanceof Image) {
            params = {
                ...params as DownloadParams,
            };
            if (!image.imageId) {
                params.timestamp = moment(image.change?.date || Date.now()).unix();
            }
            downloadUrl = image.toUrl(serviceUrl, params).toString();
        }
        else {
            const url  = new URL(serviceUrl);
            url.search = new URLSearchParams({
                ...image,
                timestamp: Date.now(),
            } as DownloadParams as Record<string, string>).toString();

            downloadUrl = url.toString();
        }
        return downloadUrl;
    }

    protected createInstanceFrom(json: PlainObject, other?: PlainObject): Image
    {
        return new Image(json);
    }

    protected override keyForEntityCache(json: Image): string
    {
        return json.key;
    }
}
