<!-- Loading bar -->
<nunc-loading-bar></nunc-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <nunc-vertical-navigation
        class=" print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container nuncVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <mat-icon svgIcon="nunc:logo" class="w-20 h-10"></mat-icon>
            </div>
        </ng-container>
    </nunc-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-start w-full min-w-0 ">

    <!-- Header -->
    <div class="header-bar flex flex-col flex-0 justify-center w-full dark:shadow-none print:hidden pb-18 sm:pb-24">
        <!-- Top bar -->
        <div class="relative flex flex-auto justify-center w-full px-4 sm:px-8">
            <div class="flex items-start sm:items-center w-full h-16 landscape:h-10 lg:landscape:h-20 md:h-24 md:border-b md:border-white md:border-opacity-20">
                <!-- Logo -->
                <ng-container>
                    <div class="flex items-center">
                        <mat-icon svgIcon="nunc:logo" class="w-20 h-10"></mat-icon>
                    </div>
                </ng-container>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                    <!-- <languages></languages>
                    <nunc-fullscreen class="hidden md:block"></nunc-fullscreen>
                    <search [appearance]="'bar'"></search>
                    <shortcuts></shortcuts>
                    <messages></messages> -->
                    <notifications *ngIf="userIsAdmin" class="bg-light"></notifications>
                    <!-- <button
                    class="lg:hidden"
                    mat-icon-button
                    (click)="quickChat.toggle()">
                    <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                    </button> -->
                    <user></user>
                </div>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                        class="rounded-md"
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:menu'" class="text-white"></mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
        <!-- Bottom bar -->
        <ng-container *ngIf="!isScreenSmall">
            <div class="flex flex-auto justify-center px-4 md:px-12">
                <div class="relative flex items-center w-full max-w-360 py-5">
                    <nunc-horizontal-navigation
                        class="-mx-4"
                        [name]="'mainNavigation'"
                        [navigation]="navigation.horizontal"></nunc-horizontal-navigation>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
        <div class="flex flex-col flex-auto w-full sm:max-w-360  sm:rounded-lg   bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">
        <div class="flex items-center w-full max-w-360 h-14 landscape:h-10 lg:landscape:h-20 sm:h-20">
            <span class="font-medium text-secondary">Nunc &copy; {{currentYear}}</span>
        </div>
    </div>

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
