import {Change, Changed, Created, EntityFilter, Versioned} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.binding.Binding <br/>
*/
export interface Binding extends  Changed, Created, Versioned{
bindingId?: number;
change?: Change;
created?: Change;
deviceConfirmDate?: string;
deviceId?: string;
deviceName?: string;
grinderName?: string;
state?: BindingState;
userConfirmDate?: string;
username?: string;
version?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.binding.BindingFilter <br/>
*/
export interface BindingFilter extends  EntityFilter{
bindingId?: number;
deviceId?: string;
deviceIds?: string[];
excludeDeviceId?: string;
excludeUsername?: string;
state?: string;
username?: string;
}

export enum BindingState {
REQUESTED = "REQUESTED",
BOUND = "BOUND",
FAILED = "FAILED"
}

