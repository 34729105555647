import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector   : 'lib-status-indicator',
    templateUrl: './status-indicator.component.html',
    styleUrls  : ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent implements OnInit
{
    @Input()
    caption!: string;

    @Input()
    outerColor!: string;

    @Input()
    innerColor!: string;

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

}
