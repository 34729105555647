import { ModuleWithProviders, NgModule } from '@angular/core';
import { NuncConfigService } from '@nunc/services/config/config.service';
import { NUNC_APP_CONFIG } from '@nunc/services/config/config.constants';

@NgModule()
export class NuncConfigModule
{
    /**
     * Constructor
     */
    constructor(private _nuncConfigService: NuncConfigService)
    {
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: any): ModuleWithProviders<NuncConfigModule>
    {
        return {
            ngModule : NuncConfigModule,
            providers: [
                {
                    provide : NUNC_APP_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
