import {Change, ChangeFilter, Changed, Created, DataRange, EntityFilter} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.system.DownloadUrlRequest <br/>
*/
export interface DownloadUrlRequest {
bundle?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.system.DownloadUrlResponse <br/>
*/
export interface DownloadUrlResponse {
url?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.system.FirmwareInfo <br/>
*/
export interface FirmwareInfo extends  Changed, Created{
bundleVersion?: string;
change?: Change;
created?: Change;
details?: {[key: string]: Object};

/**
*	@example https://nunc.dev.cloud.yukawa.de/files/firmware/1.0.1.zip
*/
downloadUrl?: string;
fwCoreVersion?: string;
fwEmbeddedVersion?: string;
fwGrinderVersion?: string;
hwDeviceVersion?: string;
hwGrinderVersion?: string;
nextBundleVersion?: string;
phase?: string;
swFrontendVersion?: string;

/**
*	@example 10000001
*/
version?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.system.FirmwareInfoFilter <br/>
*/
export interface FirmwareInfoFilter extends  EntityFilter{
bundleVersion?: string;
created?: ChangeFilter;
hwDeviceVersion?: string;
hwDeviceVersionRange?: DataRange<string> ;
hwGrinderVersion?: string;
hwGrinderVersionRange?: DataRange<string> ;
phase?: string;
phases?: string[];
version?: number;
versionRange?: DataRange<number> ;
}

export enum Phase {
GA = "GA",
TEST = "TEST",
DRAFT = "DRAFT"
}

