import { NgModule } from '@angular/core';
import { DefaultImageDirective } from './directive';


@NgModule({
    declarations: [
        DefaultImageDirective,
    ],
    exports     : [
        DefaultImageDirective,
    ],
})
export class DefaultImageModule
{
}
