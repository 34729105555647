import { IEnvironment as BaseEnvironment } from '@yukawa/chain-base-angular-client';


export enum AppEnvironment
{
    'admin'     = 'admin',
    'app'       = 'app',
    'simulator' = 'simulator',
}

export interface IEnvironment extends BaseEnvironment
{
    app: AppEnvironment;
}
