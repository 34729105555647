import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { IAuthTokenResponse, SessionService, SessionStoreService } from '@yukawa/chain-main-angular-session';
import { lastValueFrom, Observable } from 'rxjs';


@Injectable()
export class AuthService extends RestAspect
{
    /**
     * Constructor
     */
    constructor(
        httpClient: HttpClient,
        private _configService: ConfigService,
        private _sessionService: SessionService,
        private _sessionStoreService: SessionStoreService,
    )
    {
        super(httpClient, _configService, _configService.getBaseUrl() + '/auth');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    get accessToken(): string
    {
        const json = this._sessionStoreService.getJSON('token') as IAuthTokenResponse;
        return json.access_token;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<void>
    {
        return this.http.post<void>('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<void>
    {
        return this.http.post<void>('api/auth/reset-password', password);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this.http.post('api/auth/unlock-session', credentials);
    }

    /**
     * Sign in
     *
     * @param token
     */
    async refreshToken(token?: string): Promise<boolean>
    {
        this._sessionService.store.setJSON(
            'token',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            await lastValueFrom(this._sessionService.auth.refresh({ refresh_token: token } as IAuthTokenResponse)),
        );

        return this._sessionService.restore();
    }
}
