import { Address as IAddress, Change, GeoLocation, Owner, Person as IPerson } from '@yukawa/chain-base-angular-domain';
import { User as IUser } from '@yukawa/chain-main-angular-core';
import { Role as YukawaRole } from '@yukawa/chain-main-angular-session';
import { Account, GroupContext } from '@yukawa/chain-security-angular-core';


export interface Address extends IAddress
{
    addressId: string;
    city: string;
    countryCode: string;
    geoLocation: Required<GeoLocation>;
    houseNumber: string;
    region: string;
    state: string;
    street: string;
    type: string;
    zipCode: string;
}

export interface Person extends IPerson
{
    addresses: Address[];
    change: Change;
    companyName: string;
    created: Change;
    email: string;
    firstName: string;
    lang: string;
    lastName: string;
    mobile: string;
    phoneNumber: string;
    role: string;
    salutation: string;
    shortName: string;
    title: string;
    vatNumber: string;
}

export interface User extends IUser
{
    account: Account;
    change: Required<Change>;
    created: Required<Change>;
    defaultOrgId: string;
    details: Record<string, object>;
    groupContexts: Required<GroupContext>[];
    owner: Required<Owner>;
    person: Person;
    userId: string;
    username: string;

    //region UI Properties

    image: string;
    name: string;
    registeredAt: string;
    lastActiveAt: string;
    profile: string;

    //endregion
}

export class Role extends YukawaRole
{

    //region todo: remove the following roles from @yukawa/chain-main-angular-session

    //static readonly profileReader = Role.enum<Role>(0x200, 'ROLE_PROF_R');
    //static readonly contentEdit   = Role.enum<Role>(0x400, 'ROLE_CONT_E');
    //static readonly interestEdit  = Role.enum<Role>(0x800, 'ROLE_INT_E');
    //static readonly switchEnv     = Role.enum<Role>(0x1000, 'ROLE_SWITCH_ENV');

    //endregion

    static readonly firmwareEdit = Role.enum<Role>(0x2000, 'ROLE_FW_E');
    static readonly adminUi      = Role.enum<Role>(0x4000, 'ROLE_ADM_UI');
}

/**
 * Represents the credentials change request
 */
export interface CredentialsChange
{

    oldPassword: string;
    /**
     * example: yuk
     */
    orgId: string;

    /**
     * example: admin
     */
    password: string;

    /**
     * example: admin
     */
    username: string;

}
