import { MatPaginatorIntl } from '@angular/material/paginator';
import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { firstValueFrom } from 'rxjs';


const formatRange = (page: number, pageSize: number, length: number): string =>
{
    const translocoService = AppInjector.get(TranslocoService);

    // eslint-disable-next-line eqeqeq
    if (length == 0 || pageSize == 0) {
        return translocoService.translate('FORMAT.PAGINATION.RANGE_EMPTY', { length });
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return translocoService.translate('FORMAT.PAGINATION.RANGE', {
        startIndex: startIndex + 1,
        endIndex,
        length,
    });
};

let paginatorIntl: MatPaginatorIntl;

const init = (): void =>
{
    const translocoService = AppInjector.get(TranslocoService);

    paginatorIntl               = new MatPaginatorIntl();
    paginatorIntl.getRangeLabel = formatRange;

    const setTranslations = async (): Promise<void> =>
    {
        const translate = (key: string): Promise<string> => firstValueFrom(translocoService.selectTranslate(key));

        paginatorIntl.itemsPerPageLabel = await translate('FORMAT.PAGINATION.ITEMS_PER_PAGE');
        paginatorIntl.firstPageLabel    = await translate('FORMAT.PAGINATION.FIRST_PAGE');
        paginatorIntl.nextPageLabel     = await translate('FORMAT.PAGINATION.NEXT_PAGE');
        paginatorIntl.previousPageLabel = await translate('FORMAT.PAGINATION.PREVIOUS_PAGE');
        paginatorIntl.lastPageLabel     = await translate('FORMAT.PAGINATION.LAST_PAGE');
    };

    translocoService.langChanges$.subscribe((lang) =>
    {
        if (translocoService.getAvailableLangs().some(_lang => _lang === lang || (_lang as LangDefinition).id === lang)) {
            setTimeout(() => setTranslations());
        }
    });

    translocoService.events$.subscribe((event) =>
    {
        if (event.type === 'translationLoadSuccess') {
            setTranslations();
        }
    });
};

export const getPaginatorIntl = (): MatPaginatorIntl =>
{
    if (!paginatorIntl) {
        init();
    }

    return paginatorIntl;
};
