import { DownloadParams, DownloadSize, Image as IImage, ImageBody } from '@nunc/lib/domain';
import { AppInjector, Entity } from '@yukawa/chain-base-angular-client';
import { ChainKey, Change, Info } from '@yukawa/chain-base-angular-domain';
import { lastValueFrom } from 'rxjs';
import { Overwrite } from 'simplytyped';
import { ImageService } from './image.service';


export interface ImageEntity
{
    id: number | string;
    imageId?: string;
}

type PersistedImage = Overwrite<IImage, Required<Pick<IImage, 'imageId'>>>;

export class Image extends Entity<IImage> implements IImage
{
    public category: string            = null as never;
    public image: ImageBody            = null as never;
    public imageId: number             = null as never;
    public info: Info                  = null as never;
    public mime: string                = null as never;
    public position: number            = null as never;
    public related: Required<ChainKey> = null as never;
    public thumbnailLarge: ImageBody   = null as never;
    public thumbnailMedium: ImageBody  = null as never;
    public thumbnailSmall: ImageBody   = null as never;
    public change: Change              = null as never;
    public created: Change             = null as never;

    private _url?: string;
    private _smallUrl?: string;
    private _mediumUrl?: string;
    private _largeUrl?: string;

    constructor(
        initialData: PersistedImage | ImageEntity,
        info?: Info)
    {
        super();

        if ('id' in initialData) {
            initialData = ('imageId' in initialData) && initialData.imageId
                ? {
                    imageId: Number(initialData.imageId),
                } as IImage
                : {
                    ...AppInjector.get(ImageService).getImageMetaData(initialData.id, initialData.constructor.name, info),
                };
        }

        this.updateFromJson(initialData);
    }

    //region Image URLs

    get url(): string
    {
        return this._url ??= AppInjector.get(ImageService).downloadUrl(this, { size: DownloadSize.default });
    }

    get smallUrl(): string
    {
        return this._smallUrl ??= AppInjector.get(ImageService).downloadUrl(this, { size: DownloadSize.small });
    }

    get mediumUrl(): string
    {
        return this._mediumUrl ??= AppInjector.get(ImageService).downloadUrl(this, { size: DownloadSize.medium });
    }

    get largeUrl(): string
    {
        return this._largeUrl ??= AppInjector.get(ImageService).downloadUrl(this, { size: DownloadSize.large });
    }

    //endregion

    //region Entity<IImage> implementation

    public get key(): string
    {
        return String(this.imageId);
    }

    public toJson(): IImage
    {
        return this.toJsonWithKeys([
            'category',
            'imageId',
            'info',
            'related',
        ]);
    }

    public updateFromJson(data: IImage): void
    {
        this.setFromJson(data, [
            'category',
            'image',
            'imageId',
            'info',
            'mime',
            'position',
            'related',
            'thumbnailLarge',
            'thumbnailMedium',
            'thumbnailSmall',
            'change',
            'created',
        ]);
    }

    //endregion

    public toUrl(serviceUrl: string, params?: DownloadParams): URL
    {
        const url  = new URL(serviceUrl);
        url.search = new URLSearchParams(this.imageId
            ? {
                ...params,
                imageId: this.imageId,
            } as DownloadParams as Record<string, string>
            : {
                ...params,
                category     : this.category,
                relatedEntity: this.related.entity,
                relatedId    : this.related.id,
                relatedModule: this.related.module,

            } as DownloadParams as Record<string, string>).toString();

        return url;
    }

    async upload(file: File): Promise<void>
    {
        this.updateFromJson(await lastValueFrom(AppInjector.get(ImageService).upload(this, file)));
        this._url = this._smallUrl = this._mediumUrl = this._largeUrl = undefined;
    }
}
