import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule as LibSharedModule } from '@nunc/lib/shared';
import { YukawaAlertModule } from '@yukawa/chain-base-angular-comp/alert';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        YukawaAlertModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        LibSharedModule,
        ReactiveFormsModule,
        YukawaAlertModule,
    ],
})
export class SharedModule
{
}
