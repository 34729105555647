import {Address, AddressFilter, Change, ChangeFilter, Changed, Created, EntityFilter, Info, InfoFilter, LocalDateRange, Person, PersonFilter} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Author <br/>
*/
export interface Author {
authorId?: number;
person?: Person;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Bean <br/>
*/
export interface Bean {
beanId?: number;
density?: string;
farmer?: Farmer;
harvestDate?: Date;
moisture?: string;
originCountry?: string;
percentage?: number;
processing?: string;
roastId?: number;
size?: string;
variety?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.BeanFilter <br/>
*/
export interface BeanFilter extends  EntityFilter{
beanId?: number;
density?: string;
farmer?: FarmerFilter;
harvestDate?: LocalDateRange;
moisture?: string;
originCountry?: string;
processing?: string;
roastId?: number;
size?: string;
variety?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Farmer <br/>
*/
export interface Farmer {
address?: Address;
farmerId?: number;
imageUrls?: {[key: string]: string[]};
numberOfTrees?: number;
person?: Person;
setImageUrls?: undefined | {[key: string]: string[]};

/**
*	values: [FARMER, COOPERATION]<br/>
*	@example FARMER
*/
type?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.FarmerFilter <br/>
*/
export interface FarmerFilter extends  EntityFilter{
address?: AddressFilter;
farmerId?: string;
person?: PersonFilter;
type?: string;
}

export enum FarmerType {
FARMER = "FARMER",
COOPERATION = "COOPERATION"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.GrindSizeAdjustment <br/>
*/
export interface GrindSizeAdjustment {
adjustedGrindSize?: number;
grindSize?: number;
recipeId?: number;
temperature?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.GrindSizeAdjustmentRequest <br/>
*/
export interface GrindSizeAdjustmentRequest {
grindSize?: number;
recipeId?: number;
temperature?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.GrindingParams <br/>
*/
export interface GrindingParams {
beanWeight?: number;
shutterTime?: number;
size?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Label <br/>
*/
export interface Label extends  Changed, Created{
change?: Change;
created?: Change;
desc?: string;
i18n?: {[key: string]: Info};
iconId?: string;
labelId?: string;
name?: string;
shortName?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.LabelFilter <br/>
*/
export interface LabelFilter extends  EntityFilter{
desc?: string;
labelId?: string;
labelIds?: string[];
name?: string;
shortName?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Preset <br/>
*/
export interface Preset extends  Changed, Created{
change?: Change;
created?: Change;
presetId?: string;
recipe?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.PresetFilter <br/>
*/
export interface PresetFilter extends  EntityFilter{
created?: ChangeFilter;
presetId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Rating <br/>
*/
export interface Rating {
average?: number;
negativeCount?: number;
positiveCount?: number;
totalCount?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Recipe <br/>
*/
export interface Recipe extends  Changed, Created{
author?: Author;
change?: Change;
created?: Change;
info?: Info;
recipeId?: number;
roast?: Roast;
roastId?: number;
sieveRecipes?: SieveRecipe[];
version?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RecipeBlock <br/>
*/
export interface RecipeBlock {
ctrl1?: number;
ctrl2?: number;
endCond1?: number;
endCond2?: number;
endCond3?: number;
endCond4?: number;
f1?: number;
f2?: number;
f3?: number;
filter?: number;

/**
*	@example My Block
*/
name?: string;
p1?: number;
p2?: number;
position?: number;
recipeId?: number;
sieveTypeId?: number;
t1?: number;
t2?: number;
t3?: number;
t4?: number;
time?: number;
v1?: number;
v2?: number;
valves?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RecipeFilter <br/>
*/
export interface RecipeFilter extends  EntityFilter{
author?: PersonFilter;
info?: InfoFilter;
labels?: LabelFilter;
recipeId?: number;
recipeIds?: number[];
roast?: RoastFilter;
sieveTypeId?: number;
version?: number;
withRecipeBlocks?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RfidTag <br/>
*/
export interface RfidTag {
data?: number[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Roast <br/>
*/
export interface Roast {

/**
*	values: [0,1,2,3,4,5]<br/>
*/
acidity?: number;
beans?: Bean[];
category?: number;
display?: RoastDisplay;
imageId?: string;
imageUrls?: {[key: string]: string[]};
info?: Info;
labels?: Label[];
price?: number;
rating?: Rating;
recipes?: Recipe[];
roastId?: number;

/**
*	values: [0,1,2,3,4,5]<br/>
*/
roastLevel?: number;
roastery?: Roastery;
setImageUrls?: undefined | {[key: string]: string[]};
shopImageId?: string;
state?: RoastState;
thumbnailImageId?: string;
weight?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RoastDisplay <br/>
*/
export interface RoastDisplay {
accentColorHex?: string;
primaryColorHex?: string;
secondaryColorHex?: string;
titleColorHex?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RoastDonationFilter <br/>
*/
export interface RoastDonationFilter extends  EntityFilter{
created?: ChangeFilter;
donationId?: number;
roastId?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RoastFilter <br/>
*/
export interface RoastFilter extends  EntityFilter{
beans?: BeanFilter;
category?: number;
info?: InfoFilter;
labels?: LabelFilter;
recipeIds?: number[];
roastId?: number;
state?: RoastState;
withImageUrls?: boolean;
withLabels?: boolean;
withRecipes?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RoastRateFilter <br/>
*/
export interface RoastRateFilter extends  EntityFilter{
roastId?: number;
username?: string;
}

export enum RoastState {
DRAFT = "DRAFT",
RELEASED = "RELEASED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Roastery <br/>
*/
export interface Roastery {
address?: Address;
imageId?: string;
imageUrls?: {[key: string]: string[]};
person?: Person;
roasteryId?: number;
setImageUrls?: undefined | {[key: string]: string[]};
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.RoasteryFilter <br/>
*/
export interface RoasteryFilter extends  EntityFilter{
roasteryId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.Shape <br/>
*/
export interface Shape {
markerX?: number;
markerY?: number;
path?: string;
positionX?: number;
positionY?: number;
recipeId?: number;
roastId?: number;
shapeId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.ShapeFilter <br/>
*/
export interface ShapeFilter extends  EntityFilter{
recipeId?: number;
roastId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.ShotParams <br/>
*/
export interface ShotParams {
channeling?: boolean;
drink?: string;
duration?: number;
extraction?: number;
flow?: number;
gusher?: boolean;
peakPressure?: number;
peakTemperature?: number;
spentPuckWeight?: number;
tds?: number;
timeToFirstDrop?: number;
timeToPeakFlow?: number;
timeToPeakPressure?: number;
volume?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.ShotRateFilter <br/>
*/
export interface ShotRateFilter extends  EntityFilter{
brewEventId?: string;
brewEventIds?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.SieveRecipe <br/>
*/
export interface SieveRecipe {
brewingRecipe?: string;
grindBeanWeight?: number;
grindShutterTime?: number;
grindSize?: number;
recipeBlocks?: RecipeBlock[];
recipeId?: number;
sieveTypeId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.SieveRecipeFilter <br/>
*/
export interface SieveRecipeFilter extends  EntityFilter{
recipeId?: number;
sieveTypeId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.SieveType <br/>
*/
export interface SieveType {
description?: string;

/**
*	@example 1
*/
fallbackSieveTypeId?: number;

/**
*	@example 1
*/
sieveTypeId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.nunc.base.core.domain.recipe.SieveTypeFilter <br/>
*/
export interface SieveTypeFilter extends  EntityFilter{
sieveTypeId?: number;
}

