import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '@yukawa/chain-base-angular-comp/shared';


@Directive({
    selector: '[backButton]',
})
export class BackButtonDirective
{
    constructor(private _navigation: NavigationService)
    {
    }

    @HostListener('click')
    onClick(): void
    {
        this._navigation.back();
    }
}
