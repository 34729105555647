import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NuncDrawerComponent } from '@nunc/components/drawer/drawer.component';

@NgModule({
    declarations: [
        NuncDrawerComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        NuncDrawerComponent
    ]
})
export class NuncDrawerModule
{
}
