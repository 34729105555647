import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { Nullable } from 'simplytyped';
import { EmojiEvent } from '../service';


@Directive({
    selector: '[emojiTooltip]',
    exportAs: 'emojiTooltip',
})
export class EmojiTooltipDirective implements OnInit, OnDestroy
{
    @Input() title       = 'Pick your emoji…';
    @Input() pickerClass = 'emoji-picker';
    @Input() emoji       = 'point_up';
    @Input() virtualize  = true;

    @Input()
    toggleTooltip = new EventEmitter<boolean>();

    @Output()
    emojiClick = new EventEmitter<EmojiEvent>();

    private _overlayRef!: OverlayRef;
    private _toolTipRef: Nullable<ComponentRef<PickerComponent>>;
    private _mouseX: number = 0;
    private _mouseY: number = 0;

    constructor(
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private overlay: Overlay,
    )
    {
        document.addEventListener('mousemove', this.onMouseUpdate, false);
        document.addEventListener('mouseenter', this.onMouseUpdate, false);
        document.addEventListener('click', this.onDocumentClick, false);
    }

    get elementHovered(): boolean
    {
        return document.elementsFromPoint(this._mouseX, this._mouseY)
            .some(element => element === this.elementRef.nativeElement || element === this._toolTipRef?.location.nativeElement);
    }

    @HostListener('click')
    public show(): void
    {
        if (this._overlayRef.hasAttached()) {
            return;
        }
        // Create tooltip portal
        const tooltipPortal = new ComponentPortal(PickerComponent);

        // Attach tooltip portal to overlay

        this._overlayRef.detach();
        this._toolTipRef = this._overlayRef.attach(tooltipPortal);

        // Pass content to tooltip component instance
        this._toolTipRef.instance.title      = this.title;
        this._toolTipRef.instance.emoji      = this.emoji;
        this._toolTipRef.instance.virtualize = this.virtualize;
        this._toolTipRef.instance.emojiClick.subscribe((e: EmojiEvent) => this.emojiClick.emit(e));
        (this._toolTipRef.location.nativeElement as HTMLElement).classList.add(this.pickerClass);
    }

    public hide(): void
    {
        if (!this._overlayRef.hasAttached()) {
            return;
        }
        this._overlayRef.detach();
        this._toolTipRef?.destroy();
        this._toolTipRef = null;
    }

    public ngOnInit(): void
    {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                {
                    originX : 'start',
                    originY : 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]);

        this._overlayRef = this.overlay.create({ positionStrategy });

        this.toggleTooltip.subscribe(this.toggle.bind(this));
    }

    public ngOnDestroy(): void
    {
        document.removeEventListener('mousemove', this.onMouseUpdate, false);
        document.removeEventListener('mouseenter', this.onMouseUpdate, false);
        document.removeEventListener('click', this.onDocumentClick, false);
    }

    private onDocumentClick = (e: MouseEvent): void =>
    {
        if (this._toolTipRef && !this.elementHovered) {
            this.hide();
        }
    };

    private onMouseUpdate = (e: MouseEvent): void =>
    {
        this._mouseX = e.pageX;
        this._mouseY = e.pageY;
    };

    private toggle(toggle: boolean): void
    {
        if (toggle) {
            this.show();
        }
        else {
            this.hide();
        }
    }
}
