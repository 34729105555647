import { AfterViewInit, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';


@Directive({ selector: '[renderIfVisible]' })
export class RenderIfVisibleDirective implements AfterViewInit, OnDestroy
{
    @Input() public minWidth: number   = 40;
    @Input() public minHeight: number  = 40;
    @Input() public rootMargin: number = 0;
    @Input() public marginRoot!: Element;
    @Input() public className!: string;

    #intersectionObserver!: IntersectionObserver;
    #mutationObserver!: MutationObserver;
    #alreadyRendered: boolean = false;

    constructor(
        private vcRef: ViewContainerRef,
        private tplRef: TemplateRef<any>,
    )
    {
    }

    public ngAfterViewInit(): void
    {
        const templateElement = this.vcRef.element.nativeElement;
        this.setMinWidthHeight(templateElement.parentElement);
        this.observeIntersection(templateElement.parentElement);
        this.observeCLassMutation(templateElement.parentElement);
    }

    public ngOnDestroy(): void
    {
        this.#intersectionObserver.disconnect();
        this.#mutationObserver.disconnect();
    }

    private observeIntersection(targetElement: Element): void
    {
        this.#intersectionObserver = new IntersectionObserver(
            entries =>
                entries.forEach(entry => this.renderContents(entry.isIntersecting)),
            {
                root      : this.marginRoot,
                rootMargin: `${this.rootMargin}px`,
                threshold : [0],
            },
        );
        this.#intersectionObserver.observe(targetElement);
    }

    private observeCLassMutation(targetElement: Element): void
    {
        this.#mutationObserver = new MutationObserver(() =>
        {
            if (targetElement.classList.contains(this.className)) {
                this.renderContents(true);
            }
        });
        this.#mutationObserver.observe(targetElement, {
            attributeFilter: ['class'],
        });
    }

    private renderContents(isInView: boolean): void
    {
        if (isInView && !this.#alreadyRendered) {
            this.vcRef.clear();
            this.vcRef.createEmbeddedView(this.tplRef);
            this.#alreadyRendered = true;
        }
    }

    private setMinWidthHeight(el: HTMLElement): void
    {
        // preserve parent width and height
        const style           = window.getComputedStyle(el);
        const [width, height] = [parseInt(style.width, 10), parseInt(style.height, 10)];
        /* eslint-disable @typescript-eslint/no-unused-expressions */
        !width && (el.style.minWidth = `${this.minWidth}px`);
        !height && (el.style.minHeight = `${this.minHeight}px`);
        /* eslint-enable @typescript-eslint/no-unused-expressions */
    }

}
