import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ConstructorFor } from 'simplytyped';
import { IQueryTableEntry, IQueryTableEntryDetail, IQueryTableEntryDetailComponent } from './types';


@Directive({
    selector: '[libCellComponent]',
})
export class CellComponentDirective implements OnInit
{
    @Input()
    component!: ConstructorFor<IQueryTableEntryDetailComponent>;

    @Input()
    detail!: IQueryTableEntryDetail;

    @Input()
    entry!: IQueryTableEntry;

    constructor(
        private readonly viewContainer: ViewContainerRef,
    )
    {
    }

    ngOnInit(): void
    {
        const expandedComponent = this.viewContainer.createComponent(this.component);

        expandedComponent.instance.detail = this.detail;
        expandedComponent.instance.entry  = this.entry;
    }

}
