import { Info } from '@yukawa/chain-base-angular-domain';
import { Group as IGroup } from '@yukawa/chain-main-angular-core';


export class Group implements IGroup
{
    name!: string;
    info!: Required<Info>;

    constructor(
        group: Group,
    )
    {
        Object.assign(this, group);
    }

    toString(): string
    {
        return this.info.name;
    }
}
