import { NgModule } from '@angular/core';
import { NuncMediaWatcherService } from '@nunc/services/media-watcher/media-watcher.service';

@NgModule({
    providers: [
        NuncMediaWatcherService
    ]
})
export class NuncMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _nuncMediaWatcherService: NuncMediaWatcherService)
    {
    }
}
