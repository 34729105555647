import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { fadeIn } from '@nunc/lib/animations';
import { lastValueFrom } from 'rxjs';
import { IJsonDocument, JsonDocumentReader } from './json-document-reader';


@Component({
    selector   : 'lib-json-document',
    templateUrl: './json-document.component.html',
    styleUrls  : ['./json-document.component.scss'],
    animations : [fadeIn],
})
export class JsonDocumentComponent implements OnInit
{
    @Input()
    private url!: string;

    reader!: JsonDocumentReader;

    constructor(
        private readonly _http: HttpClient,
    )
    {

    }

    async ngOnInit(): Promise<void>
    {
        this.reader = new JsonDocumentReader(await lastValueFrom(this._http.get<IJsonDocument>(this.url)));
    }
}
