/* eslint-disable @typescript-eslint/member-ordering */
import { AppInjector, Entity } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { IProfile as IProfile } from './profile.model';
import { UserService } from './user.service';


export class Profile extends Entity<IProfile> implements IProfile
{
    public change!: Required<Change>;
    public created!: Required<Change>;
    public displayName!: string;
    public username!: string;
    public imageUrl!: string;
    public image!: null;
    public verified!: boolean;

    constructor(initialData: IProfile)
    {
        super(initialData as IProfile);
    }

    //region Public Properties

    public get key(): string
    {
        return this.username;
    }

    public get isAdmin(): boolean
    {
        return this.username === 'admin';
    }

    public get isCurrentUser(): boolean
    {
        return AppInjector.get(UserService).user?.username === this.username;
    }

    //endregion

    public updateFromJson(data: IProfile & { verifiedDate: Date }): void
    {
        this.setFromJson(data, [
            'change',
            'created',
            'displayName',
            'username',
        ], undefined, {});
    }

    public toJson(): IProfile
    {
        return this.toJsonWithKeys([
            'displayName',
            'username',
        ], {});
    }
}
