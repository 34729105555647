import { Loader } from '@googlemaps/js-api-loader';
import { AppInjector, ConfigService } from '@yukawa/chain-base-angular-client';
import { run } from '@yukawa/chain-base-angular-comp/splash-screen';
import { AppModule } from 'app/app.module';
import { environment } from 'environments';


declare global
{
    interface Window
    {
        importGoogleMaps: () => Promise<void>;
    }
}

const importGoogleMaps = async (): Promise<void> =>
{
    const loader = new Loader({
        apiKey: AppInjector.get(ConfigService).getValue('googleMapsApiKey'),
        version: 'weekly',
    });

    await loader.importLibrary('maps');
    await loader.importLibrary('places');
};

Object.defineProperty(window, 'importGoogleMaps', {
    get: (): () => Promise<void> => importGoogleMaps,
});

run(AppModule, environment.production);
