import { Directive, ElementRef, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormControl, NgControl, NgModel } from '@angular/forms';


@Directive({
    selector: '[formControl], [formControlName]',
})
export class NativeElementInjectorDirective implements OnInit
{
    constructor(
        private el: ElementRef,
        private control: NgControl,
        @Optional() private model: NgModel,
    )
    {
    }

    public ngOnInit(): void
    {
        if (!!this.model) {
            (this.model.control as NativeElementFormControl).nativeElement = this.el.nativeElement;
        }
        else {
            (this.control.control as NativeElementNgControl).nativeElement = this.el.nativeElement;
        }
    }
}

export interface NativeElementFormControl extends FormControl
{
    nativeElement: HTMLElement;
}

export interface NativeElementNgControl extends AbstractControl
{
    nativeElement: HTMLElement;
}
