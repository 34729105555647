import { NgModule } from '@angular/core';
import { NuncUtilsService } from '@nunc/services/utils/utils.service';

@NgModule({
    providers: [
        NuncUtilsService
    ]
})
export class NuncUtilsModule
{
    /**
     * Constructor
     */
    constructor(private _nuncUtilsService: NuncUtilsService)
    {
    }
}
