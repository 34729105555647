import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { CloseButtonComponent } from './close-button.component';


@NgModule({
    exports     : [
        CloseButtonComponent,
    ],
    declarations: [
        CloseButtonComponent,
    ],
    imports     : [
        RouterModule,
        CommonModule,
        TranslocoModule,
        MatIconModule,
        MatButtonModule,
    ],
})
export class CloseButtonModule
{
}
