import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { fadeIn } from '@nunc/lib/animations';
import { EmojiService, MarkedPipe } from '@nunc/lib/shared';
import { BehaviorSubject } from 'rxjs';
import { Nullable } from 'simplytyped';
import { QueryTableEntry } from '../../model';
import { IQueryTableEntryDetail } from '../../types';


@Component({
    selector       : 'lib-query-table-entry-details',
    templateUrl    : './details.component.html',
    styleUrls      : ['./details.component.scss'],
    animations     : [fadeIn],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryTableEntryDetailsComponent implements OnInit, OnChanges
{
    @Input()
    entry!: QueryTableEntry<any, any>;

    @Input()
    ignoreDetails: Nullable<Array<string>>;

    @Input()
    viewDirection: 'horizontal' | 'vertical' = 'vertical';

    readonly details$         = new BehaviorSubject<Array<IQueryTableEntryDetail>>([]);
    readonly filteredDetails$ = new BehaviorSubject<Array<IQueryTableEntryDetail>>([]);
    readonly detailValues     = new Map<string, Map<string, unknown>>();

    private readonly _markedPipe = new MarkedPipe();

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _emojiService: EmojiService,
    )
    {
    }

    ngOnInit(): void
    {
        this.detailValues.clear();
        this.details$.next([
            ...Array.from(this.entry.details.values())
                .filter(detail => detail.showInDetails && (!this.ignoreDetails || !this.ignoreDetails.includes(detail.key))),
        ]);
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (changes['entry']) {
            this.ngOnInit();
            this._changeDetector.markForCheck();
        }
    }

    isLongValue(value: unknown): boolean
    {
        if (typeof value === 'string') {
            return value.length > 32;
        }
        return false;
    }

    containsSpaces(value: unknown): boolean
    {
        if (typeof value === 'string') {
            return value.indexOf(' ') > -1;
        }
        return false;
    }

    public getDetailValue<T = unknown>(detail: IQueryTableEntryDetail): T
    {
        let rowMap: Map<string, unknown>;

        if (!this.detailValues.has(this.entry.id)) {
            rowMap = new Map();
            this.detailValues.set(this.entry.id, rowMap);
        }
        else {
            rowMap = this.detailValues.get(this.entry.id) as Map<string, unknown>;
        }

        if (rowMap.has(detail.key)) {
            return rowMap.get(detail.key) as T;
        }

        let value = detail.value || '';
        if (detail?.emojiSupport) {
            value = this._emojiService.colonsToNative(value);
        }
        if (detail?.markdownSupport) {
            value = this._markedPipe.transform(value);
        }

        if (Array.isArray(value)) {
            value = value.join(', ');
        }

        rowMap.set(detail.key, value);

        return value;
    }

}
