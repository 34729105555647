import { Injector, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NuncModule } from '@nunc';
import { NuncConfigModule } from '@nunc/services/config';
import { MockApiModule, setAppInjector } from '@yukawa/chain-base-angular-client';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { MarkdownModule } from 'ngx-markdown';


const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    initialNavigation        : 'disabled',
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Nunc, NuncConfig & NuncMockAPI
        NuncModule,
        NuncConfigModule.forRoot(appConfig),
        MockApiModule.forRoot(mockApiServices),
        MatSnackBarModule,
        // Core module of your application
        CoreModule,
        IonicStorageModule.forRoot(),
        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
    ],
    bootstrap   : [
        AppComponent,
    ],
})
export class AppModule
{
    constructor(injector: Injector)
    {
        setAppInjector(injector);
    }
}
