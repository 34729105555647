import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { PlainObject } from 'simplytyped';


@Injectable()
export class HammerConfig extends HammerGestureConfig
{
    override overrides: PlainObject = {
        pinch : { enable: false },
        rotate: { enable: false },
        swipe : { direction: Hammer.DIRECTION_ALL },
    };

    override buildHammer(element: HTMLElement): HammerManager
    {
        const mc = new (window as any).Hammer(element) as HammerManager;

        for (const eventName in this.overrides) {
            if (eventName) {
                mc.get(eventName).set(this.overrides[eventName]);
            }
        }

        return mc;
    }
}
