import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import emojiRegex from 'emoji-regex';
import { Observable, tap } from 'rxjs';
import { Nullable } from 'simplytyped';


export type EmojiEvent = string | Event & { emoji: EmojiData };

@Injectable({
    providedIn: 'root',
})
export class EmojiService
{

    // eslint-disable-next-line max-len
    emojiMatch: RegExp     = emojiRegex();
    colonPairMatch: RegExp = /(\:)(\w|\+|\-)+(\:)/;

    constructor(private emojiSearch: EmojiSearch)
    {

    }

    /**
     * Turns a string with colon emojis into native form.
     *
     * @param s The string to convert into native emoji format
     */
    public colonsToNative(s: string): string
    {
        return this.replace(s, this.colonPairMatch, (matched: string) => this.mapStringToEmoji(matched))
            .replace(/\<\>\<\>/, ':');
    }

    public removeColons(s: string): string
    {
        return this.replace(s, this.colonPairMatch, (matched: string) => '')
            .replace(/\<\>\<\>/, '');
    }

    /**
     * Turns a string with native emojis into a colon form and removes unknown emojis and ZWJ Sequences.
     *
     * @param s The string to convert into colon emoji format
     */
    public nativeEmojiToColons(s: string): string
    {
        return this.replace(s, this.emojiMatch, (matched: string) => this.mapEmojiToString(matched) || '');
    }

    public removeNative(s: string): string
    {
        return this.replace(s, this.emojiMatch, (matched: string) => '');
    }

    public remove(s: string): string
    {
        return this.removeNative(this.removeColons(s));
    }

    public transformColonToNative(
        formControl: FormControl,
        input: HTMLInputElement | HTMLTextAreaElement): Observable<string>
    {
        return formControl.valueChanges.pipe(
            tap((value: string) =>
            {
                const selectionStart = input.selectionStart ?? 0;
                const newValue       = this.colonsToNative(value);

                if (value !== newValue) {
                    formControl.setValue(newValue);

                    const testText       = value.slice(0, (selectionStart || 1) - 1);
                    const lastColPos     = testText.lastIndexOf(':');
                    input.selectionStart = lastColPos + 2;
                    input.selectionEnd   = lastColPos + 2;
                }
            }),
        );
    }

    public insert(emoji: EmojiEvent, input: HTMLInputElement | HTMLTextAreaElement): void
    {
        let char: string;
        if (typeof emoji === 'string') {
            char = emoji;
        }
        else {
            char = emoji.emoji.native as string;
        }
        input.focus();

        if (document.execCommand) {
            const event = new Event('input');
            document.execCommand('insertText', false, char);
            return;
        }
        // insert emoji on caret position
        const [start, end] = [input.selectionStart, input.selectionEnd];
        input.setRangeText(char, start as number, end as number, 'end');
    }

    private replace(
        s: string,
        colonPairMatch: RegExp,
        replacer: (matched: string, p1?: string) => string,
    ): string
    {
        const regExp = new RegExp(colonPairMatch, 'gm');
        let regExpExecArray: RegExpExecArray | null;

        while ((regExpExecArray = regExp.exec(s))) {
            s = s.replace(regExp, replacer);
        }

        return s;
    }

    private mapEmojiToString(emoji: string): Nullable<string>
    {
        switch (emoji) {
            case '🅰':
                return ':a:';
            case '🅱':
                return ':b:';
            case '❤':
                return ':heart:';
            case '☺':
                return ':relaxed:';
            default: {
                const emojiList: EmojiData[] = Object.values(this.emojiSearch.emojisList);
                return emojiList.find(e => e.native === emoji)?.colons;
            }
        }
    }

    private mapStringToEmoji(emojiString: string): string
    {
        const emojiList: EmojiData[] = Object.values(this.emojiSearch.emojisList);
        let result                   = emojiList.find(e => e.colons === emojiString)?.native;
        if (result === undefined) {
            result = emojiString.replace(/\:/, '<><>');
        }
        return result;
    }
}
