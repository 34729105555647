import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ConstructorFor } from 'simplytyped';
import { FormFieldConfig, IEntryDetailFormFieldComponent } from './form.types';


@Directive({
    selector: '[libFormFieldComponent]',
})
export class FormFieldComponentDirective implements OnInit
{
    @Input()
    component!: ConstructorFor<IEntryDetailFormFieldComponent>;

    @Input()
    config!: FormFieldConfig;

    constructor(
        private readonly viewContainer: ViewContainerRef,
    )
    {
    }

    ngOnInit(): void
    {
        if (!this.component) {
            return;
        }
        const expandedComponent = this.viewContainer.createComponent(this.component);

        expandedComponent.instance.config = this.config;
    }

}
