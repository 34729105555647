import { Validators } from '@angular/forms';
import { EntryDetailType, IQueryTableEntryDetail, ISelectOption, QueryTableEntry } from '@nunc/lib/components';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { Address, Person } from '@yukawa/chain-base-angular-domain';
import { Group } from '@yukawa/chain-main-angular-core';
import { User as IUser } from '@yukawa/chain-main-angular-core/gen/chain/main/user';
import { cloneDeep } from 'lodash-es';
import { PlainObject, StringKeys } from 'simplytyped';
import { GroupService } from './group.service';
import { User } from './user.model';


export class UserTableEntry extends QueryTableEntry<User, IUser>
{
    static viewConfig: User = {
        userId       : '',
        username     : '',
        account      : {
            details     : {},
            credentials : {
                username: '',
                password: '',
                orgId   : '',
            },
            roleContexts: [
                {
                    orgId: '',
                    roles: [''],
                },
            ],
            status      : {
                accountNonExpired    : true,
                accountNonLocked     : true,
                credentialsNonExpired: true,
                enabled              : true,
            },
        },
        defaultOrgId : 'nunc',
        details      : {},
        image        : '',
        name         : '',
        owner        : {
            groups: [],
            user  : '',
        },
        person       : {
            addresses  : [
                {
                    addressId  : '',
                    city       : '',
                    countryCode: '',
                    houseNumber: '',
                    geoLocation: {
                        altitude : 0,
                        latitude : 0,
                        longitude: 0,
                    },
                    region     : '',
                    state      : '',
                    street     : '',
                    type       : 'main',
                    zipCode    : '',
                },
            ],
            change     : {
                date : new Date(),
                notes: '',
                user : '',
            },
            companyName: '',
            created    : {
                date : new Date(),
                notes: '',
                user : '',
            },
            email      : '',
            firstName  : '',
            lang       : '',
            lastName   : '',
            mobile     : '',
            phoneNumber: '',
            role       : '',
            salutation : '',
            shortName  : '',
            title      : '',
            vatNumber  : '',
        },
        groupContexts: [
            {
                groups: ['USERS'],
                orgId : 'nunc',
                userId: '',
            },
        ],
        created      : {
            date : new Date(),
            notes: '',
            user : '',
        },
        registeredAt : '',
        lastActiveAt : '',
        profile      : '',
        change       : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };

    public readonly id: string;
    public readonly name: string;

    public constructor(
        user: User = UserTableEntry.viewConfig,
    )
    {
        super(user);

        this.id   = user.username;
        this.name = user.username;
        if (user.person.addresses.length === 0) {
            user.person.addresses.push(cloneDeep(UserTableEntry.viewConfig.person.addresses[0]));
        }

        if (user.created) {
            user.created.date = new Date(user.created.date);
        }
        if (user.change) {
            user.change.date = new Date(user.change.date);
        }

        if (user.groupContexts[0]) {
            user.groupContexts[0].groups = user.groupContexts[0].groups
                .map(group => AppInjector.get(GroupService).groups
                    .find(_group => _group.name === group)) as never;
        }

        user.image        = null as never;
        user.name         = null as never;
        user.registeredAt = null as never;
        user.lastActiveAt = null as never;
        user.profile      = null as never;
    }

    public get viewConfig(): IUser
    {
        return UserTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'USER.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = User>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value   = item?.[key];
        let options = new Array<ISelectOption>();
        if (!detail.entityName) {
            detail.entityName = 'User';
        }

        switch ((key as StringKeys<User> | StringKeys<Person> | StringKeys<Address>)) {
            case 'account':
            case 'defaultOrgId':
            case 'details':
            case 'owner':
            case 'username':
                return;
            case 'registeredAt':
                type                = 'date';
                value               = item['created' as keyof User]?.date;
                detail.groupByField = 'created.date';
                break;
            case 'lastActiveAt':
                type                = 'date';
                value               = item['change' as keyof User]?.date;
                detail.groupByField = 'change.date';
                break;
            case 'change':
                /*this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });*/
                return;
            case 'created':
                /*this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });*/
                return;
            case 'groupContexts':
                type               = 'multiselect';
                detail.canEdit     = true;
                detail.required    = true;
                detail.sortable = false;
                detail.validators  = [Validators.minLength(1)];
                detail.compareWith = (o1: Group | string, o2: Group | string): boolean =>
                {
                    const id1 = typeof o1 === 'string' ? o1 : o1?.name;
                    const id2 = typeof o2 === 'string' ? o2 : o2?.name;

                    return id1 === id2;
                };
                const groups       = AppInjector.get(GroupService).groups;
                if (!value) {
                    value = ['USERS'];
                }
                else if (value[0]) {
                    value = value[0].groups.map((group: string | Group) =>
                        groups.find(_group => _group.name === (typeof group === 'string' ? group : group?.name)));
                }
                options = groups.map(group => ({
                    name : group.info?.name as string,
                    value: group,
                }));
                break;
            case 'image':
                type                  = 'image';
                detail.tableGroup     = true;
                detail.group          = 'person';
                detail.groupIndex     = 0;
                detail.groupDirection = 'horizontal';
                detail.required       = false;
                detail.defaultImage   = 'assets/images/avatars/profile.jpg';
                detail.class          = 'rounded-full w-10 h-10';
                break;
            case'name':
                type                  = 'text';
                detail.class          = 'font-bold';
                detail.showInDetails  = false;
                detail.tableGroup     = true;
                detail.group          = 'person';
                detail.groupIndex     = 1;
                detail.groupDirection = 'vertical';
                value                 = `${(item['person'] as Person)['firstName'] || ''} ${(item['person'] as Person)['lastName'] || ''}`.trim();
                break;
            case 'person':
                detail.entityName = 'Person';
                detail.required   = true;
                this.mapDetails<Person>(details, value, 'firstName', {
                    ...detail,
                    canEdit    : true,
                    showInTable: false,
                    group      : 'person',
                });
                this.mapDetails<Person>(details, value, 'lastName', {
                    ...detail,
                    canEdit    : true,
                    showInTable: false,
                    group      : 'person',
                });
                this.mapDetails<Person>(details, value, 'email', {
                    ...detail,
                    canEdit       : true,
                    tableGroup    : true,
                    groupByField  : true,
                    group         : 'person',
                    groupIndex    : 1,
                    groupDirection: 'vertical',
                    entityName    : 'main_user_username_idx',
                    validators    : [Validators.email],
                });
                detail.groupDirection = 'horizontal';
                detail.showInTable    = false;
                for (const _address of value.addresses) {
                    this.mapDetails<Address>(details, _address, 'street', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                        required  : false,
                    });
                    this.mapDetails<Address>(details, _address, 'houseNumber', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                        required  : false,
                    });
                    this.mapDetails<Address>(details, _address, 'city', {
                        ...detail,
                        group       : key + '.addresses',
                        groupIndex  : 1,
                        groupByField: true,
                        tableGroup  : true,
                        canEdit     : true,
                        required    : false,
                    });
                    this.mapDetails<Address>(details, _address, 'countryCode', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                        required  : false,
                    });
                }
                return;
            case 'profile':
                type                 = 'component';
                detail.required      = true;
                detail.showInDetails = false;
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
