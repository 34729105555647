import { IEnvironment, LogTypes } from '@yukawa/chain-base-angular-client';


export const environment: IEnvironment = {
    production: false,
    logTypes  : [
        LogTypes.error,
        LogTypes.warn,
    ],
};
