// noinspection PointlessArithmeticExpressionJS
import { ArgumentException, Exception, isNumber, TemplateString, TemplateStringValues } from '@yukawa/chain-base-angular-client';


export const semVerRexExp = new RegExp(
    // eslint-disable-next-line max-len
    '^(?<major>0|[1-9]\\d*)\\.(?<minor>0|[1-9]\\d*)\\.(?<patch>0|[1-9]\\d*)(?:-(?<prerelease>(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\\.(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\\+(?<buildmetadata>[0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?$');

interface IVersion
{
    major: number;
    minor: number;
    patch: number;
    prerelease?: string;
    buildmetadata?: string;
}

export class Version implements IVersion
{
    major!: number;
    minor!: number;
    patch!: number;
    prerelease?: string;
    buildmetadata?: string;

    constructor(data?: Partial<IVersion> | string | number)
    {
        if (typeof data === 'string') {
            data = Version.parse(data);
        }
        else if (typeof data === 'number') {
            const parts = this.unmaskVersion(data).split('.');

            this.major = parseInt(parts[0], 10);
            this.minor = parseInt(parts[1], 10);
            this.patch = parseInt(parts[2], 10);
            return;
        }

        this.major         = data?.major ?? 0;
        this.minor         = data?.minor ?? 0;
        this.patch         = data?.patch ?? 0;
        this.prerelease    = data?.prerelease;
        this.buildmetadata = data?.buildmetadata;
    }

    public static parse(text: string): Version
    {
        const data: IVersion = {
            major: 0,
            minor: 0,
            patch: 0,
        };

        const version = semVerRexExp.exec(text);

        if (!version) {
            throw new VersionException('Invalid semVer version string. ' + text);
        }

        for (const _versionPart in version.groups) {
            if (!version.groups[_versionPart]) {
                continue;
            }

            switch (_versionPart as keyof IVersion) {
                case 'major':
                    data.major = parseInt(version.groups[_versionPart], 10);
                    break;
                case 'minor':
                    data.minor = parseInt(version.groups[_versionPart], 10);
                    break;
                case 'patch':
                    data.patch = parseInt(version.groups[_versionPart], 10);
                    break;
                case 'prerelease':
                    data.prerelease = version.groups[_versionPart];
                    break;
                case 'buildmetadata':
                    data.buildmetadata = version.groups[_versionPart];
                    break;
            }
        }

        return new Version(data);
    }

    // noinspection JSUnusedGlobalSymbols
    public toLong(): number
    {
        for (const _version of [
            { name: 'major', part: this.major },
            { name: 'minor', part: this.minor },
            { name: 'patch', part: this.patch },
        ]) {
            if (!isNumber(_version.part) || _version.part < 0 || _version.part > 255) {
                throw new ArgumentException('Version must be type of number and range between 0-255.', _version.name);
            }
        }
        return this.maskVersion(`${this.major}.${this.minor}.${this.patch}`);
    }

    public toString(): string
    {
        let version = [this.major, this.minor, this.patch].join('.');
        if (this.prerelease) {
            version += `-${this.prerelease}`;
        }
        if (this.buildmetadata) {
            version += `+${this.buildmetadata}`;
        }
        return version;
    }

    /**
     * @param version should be a string in the format of 'vX.Y.Z' where X, Y, Z are
     * For example, 'v1.2.3' represents version 1.2.3.
     * @param maxBits is the number of bits used to represent the number each version number: [vMAJOR.MINOR.PATCH].
     * 8 bits are enough to represent the number [v0.0.0] to [v255.255.255].
     * @returns An unique integer representing the version.
     */
    protected maskVersion(version: string, maxBits = 8): number
    {
        const versions = version.replace('v', '')
            .split('.')
            .map(e => Number(e));

        const major = versions[0];
        const minor = versions[1];
        const patch = versions[2];
        /* eslint-disable no-bitwise */
        return major << maxBits * 2 | minor << maxBits * 1 | patch << maxBits * 0;
        /* eslint-enable no-bitwise */
    }

    /**
     * @param version should be the integer returned by [maskVersion].
     * @param maxBits is the number of bits used to represent the number each version number: [vMAJOR.MINOR.PATCH].
     * 8 bits are enough to represent the number [v0.0.0] to [v255.255.255].
     * @return the original string representing the version.
     */
    protected unmaskVersion(version: number, maxBits = 8): string
    {
        /* eslint-disable no-bitwise */
        const major = (version >> maxBits * 2) & ((1 << maxBits) - 1);
        const minor = (version >> maxBits * 1) & ((1 << maxBits) - 1);
        const patch = (version >> maxBits * 0) & ((1 << maxBits) - 1);
        /* eslint-enable no-bitwise */

        return `${major}.${minor}.${patch}`;
    }
}

export class VersionException extends Exception
{
    constructor(
        message: TemplateString,
        messageTemplateValues?: TemplateStringValues,
        innerException?: Error)
    {
        super(message, messageTemplateValues, innerException);

        VersionException.setPrototype(this);
    }
}
