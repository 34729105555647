import { Input, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment, { MomentInput } from 'moment';
import { LocaleSpecification } from 'moment/moment';


export type DateFormat = 'short' | 'long';

@Pipe({
    name: 'elapsedDate',
})
export class ElapsedDatePipe implements PipeTransform
{
    static _localeSpecifications = new Map<string, LocaleSpecification>();

    @Input()
    withoutSuffix: boolean = false;

    @Input()
    dateFormat: DateFormat = 'long';

    constructor(
        private _translocoService: TranslocoService,
    )
    {

    }

    get localeResourceKey(): string
    {
        let key = 'FORMAT.ELAPSED_DATE';
        if (this.dateFormat === 'short') {
            key += '_SHORT';
        }
        return key;
    }

    transform(value: MomentInput, ...args: unknown[]): string | undefined
    {
        if (value == null) {
            return;
        }

        const activeLang = this._translocoService.getActiveLang();
        const langKey    = `${activeLang}-${this.dateFormat}`;
        if (!ElapsedDatePipe._localeSpecifications.has(langKey)) {
            this.initLocale(langKey);
        }
        moment.updateLocale(activeLang, ElapsedDatePipe._localeSpecifications.get(langKey) as LocaleSpecification);

        let secondsElapsed = moment().diff(value, 'seconds');
        if (secondsElapsed < 0) {
            secondsElapsed *= -1;
        }
        const dayStart = moment(value).startOf('day').seconds(secondsElapsed);

        if (secondsElapsed > 300) {
            return moment(value).fromNow(this.withoutSuffix);
        }
        else if (secondsElapsed < 60) {
            return this._translocoService.translate(this.localeResourceKey + '.SECOND').replace(
                '%d',
                dayStart.format('s'),
            );
        }
        else {
            return this._translocoService.translate(this.localeResourceKey + '.MINUTE').replace(
                '%d',
                dayStart.format('m'),
            );
        }
    }

    private initLocale(lang: string): void
    {
        ElapsedDatePipe._localeSpecifications.set(lang, {
            relativeTime: {
                /* eslint-disable @typescript-eslint/naming-convention */
                future: this._translocoService.translate(this.localeResourceKey + '.FUTURE'),
                past  : this._translocoService.translate(this.localeResourceKey + '.PAST'),
                s     : this._translocoService.translate(this.localeResourceKey + '.SECOND'),
                ss    : this._translocoService.translate(this.localeResourceKey + '.SECONDS'),
                m     : this._translocoService.translate(this.localeResourceKey + '.MINUTE'),
                mm    : this._translocoService.translate(this.localeResourceKey + '.MINUTES'),
                h     : this._translocoService.translate(this.localeResourceKey + '.HOUR'),
                hh    : this._translocoService.translate(this.localeResourceKey + '.HOURS'),
                d     : this._translocoService.translate(this.localeResourceKey + '.DAY'),
                dd    : (days: number) =>
                {
                    // round to the closest number of weeks
                    const weeks = Math.round(days / 7);
                    if (days < 7) {
                        // if less than a week, use days
                        return this._translocoService.translate(days === 1
                            ? this.localeResourceKey + '.DAY'
                            : this.localeResourceKey + '.DAYS')
                            .replace('%d', String(days));
                    }
                    else {
                        // pluralize weeks
                        return this._translocoService.translate(weeks === 1
                            ? this.localeResourceKey + '.WEEK'
                            : this.localeResourceKey + '.WEEKS')
                            .replace('%d', String(weeks));
                    }
                },
                w     : this._translocoService.translate(this.localeResourceKey + '.WEEK'),
                ww    : this._translocoService.translate(this.localeResourceKey + '.WEEKS'),
                M     : this._translocoService.translate(this.localeResourceKey + '.MONTH'),
                MM    : this._translocoService.translate(this.localeResourceKey + '.MONTHS'),
                y     : this._translocoService.translate(this.localeResourceKey + '.YEAR'),
                yy    : this._translocoService.translate(this.localeResourceKey + '.YEARS'),
                /* eslint-enable @typescript-eslint/naming-convention */
            },
        });
    }
}
