import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@nunc/lib/shared';
import { QueryTableEntryDetailsComponent } from './details.component';


@NgModule({
    declarations: [
        QueryTableEntryDetailsComponent,
    ],
    imports     : [
        CommonModule,
        MatCheckboxModule,
        MatTooltipModule,
        SharedModule,
        TranslocoModule,
    ],
    exports     : [
        QueryTableEntryDetailsComponent,
    ],
})
export class QueryTableEntryDetailsModule
{
}
