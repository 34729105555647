import { EventEmitter } from '@angular/core';
import '@awesome-nodes/object/data';
import { DeviceFilter } from '@nunc/lib/domain';
import { AppInjector, ConfigService, Exception, TemplateString, TemplateStringValues } from '@yukawa/chain-base-angular-client';
import { EntityFilter, QueryResult, TableFilter } from '@yukawa/chain-base-angular-domain';
import { cloneDeep } from 'lodash-es';
import { Nullable, PlainObject } from 'simplytyped';
import { IQueryTableEntry } from '../types';
import { QueryTableEntry } from './query-table-entry.model';


export abstract class QueryTableDatasource<TEntity extends object = PlainObject, TFilter extends TableFilter = EntityFilter>
{
    readonly entries       = new Array<IQueryTableEntry<TEntity>>();
    readonly entriesLoaded = new EventEmitter<Array<IQueryTableEntry<TEntity>>>();
    readonly entrySelected = new EventEmitter<IQueryTableEntry<TEntity>>();
    readonly entryUpdated  = new EventEmitter<IQueryTableEntry<TEntity>>();
    readonly entryDeleted  = new EventEmitter<IQueryTableEntry<TEntity>>();

    protected query!: QueryResult<TEntity>;

    private _queryResult!: QueryResult<IQueryTableEntry<TEntity>>;

    protected constructor(
        private readonly _defaultFilter: TFilter         = {} as TFilter,
        protected readonly _configService: ConfigService = AppInjector.get(ConfigService),
    )
    {
        this._defaultFilter = Object.seal(this._defaultFilter);
    }

    public get defaultFilter(): DeviceFilter
    {
        return cloneDeep(this._defaultFilter);
    }

    public get queryResult(): Nullable<QueryResult<IQueryTableEntry<TEntity>>>
    {
        return this._queryResult;
    }

    public async init(filter?: TFilter): Promise<QueryResult<IQueryTableEntry<TEntity>>>
    {
        const files = await this.read(filter);

        if (!files) {
            if (this.query.messages.some((message) =>
            {
                const msg = message.type.toLowerCase();
                return msg.includes('error') || msg.includes('exception');
            })) {
                let message = JSON.safeStringify(this.query.messages);
                // remove jason braces, brackets and quotes from message
                message     = message.replace(/[\[\]{}"]/g, '');
                throw new QueryTableDatasourceException(
                    `Error while loading data.\n${message}`,
                    { messages: this._queryResult.messages },
                );
            }
        }
        else {
            if (!files.some(file => file instanceof QueryTableEntry)) {
                this.entries.length = 0;
                this.entries.push(...files.map(item => this.newEntry(item)));
            }

            if (this.query) {
                this._queryResult = {
                    ...this.query as any,
                    items: this.entries,
                };
            }

            this.entriesLoaded.emit(this.entries);
        }

        return this._queryResult;
    }

    public selectEntry(id?: string): void
    {
        this.entries.forEach((entry) =>
        {
            entry.selected = entry.id === id;
            if (entry.selected) {
                this.entrySelected.emit(entry);
            }
        });

        if (!this.entries.some(entry => entry.selected)) {
            this.entrySelected.emit();
        }
    }

    public abstract newEntry(entity?: TEntity): IQueryTableEntry<TEntity>;

    public abstract create(entry: IQueryTableEntry<TEntity>): Promise<IQueryTableEntry<TEntity>>;

    public abstract read(filter?: TFilter): Promise<Array<TEntity>>;

    public abstract update(entry: IQueryTableEntry<TEntity>, entity?: TEntity): Promise<IQueryTableEntry<TEntity>>;

    public abstract delete(entry: IQueryTableEntry<TEntity>): Promise<void>;

    public abstract getByID(id: number | string): Promise<IQueryTableEntry<TEntity>>;
}

export class QueryTableDatasourceException extends Exception
{
    public constructor(
        message: TemplateString,
        messageTemplateValues?: TemplateStringValues,
        innerException?: Error | undefined)
    {
        super(message, messageTemplateValues, innerException);

        QueryTableDatasourceException.setPrototype(this);
    }
}
