import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, EntityRepository, RestAspect } from '@yukawa/chain-base-angular-client';
import { Session, SessionChangedEventArgs, SessionService } from '@yukawa/chain-main-angular-session';
import { Credentials } from '@yukawa/chain-security-angular-core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Profile } from './profile.entity';
import { IProfile } from './profile.model';
import { User } from './user.model';
import { UserService } from './user.service';


@Injectable()
export class ProfileService extends RestAspect
{
    private _profile: Nullable<Profile>;
    private _lastResponse: Nullable<HttpResponse<User>> = null;
    private _profileRepository                          = new EntityRepository<Profile>({
        createInstanceFrom: this.createInstanceFrom.bind(this),
    });

    public constructor(
        http: HttpClient,
        _configService: ConfigService,
        private readonly _sessionService: SessionService,
        private readonly _userService: UserService,
    )
    {
        super(http, _configService, _configService.formatUrl('profileUrl'));

        _sessionService.sessionChanged.subscribe(async (session: Session, ea?: SessionChangedEventArgs) =>
        {
            if (ea?.session instanceof Session.SessionConnected) {
                await this.loadProfile();
            }
            else {
                this._profile = null;
            }
        });
    }

    public get lastResponse(): Nullable<HttpResponse<unknown>>
    {
        return this._lastResponse;
    }

    get profile(): Nullable<Profile>
    {
        return this._profile;
    }

    async init(): Promise<boolean>
    {
        return this.loadProfile();
    }

    async loadProfile(): Promise<boolean>
    {
        if (this._sessionService.auth.isAuthenticated) {
            try {
                this._profile = await lastValueFrom(this.getProfile());
            }
            catch (e) {
                console.error(e);
            }
        }

        return this._profile != null;
    }

    getCode(username?: string): Observable<User>
    {
        const body: Credentials = { username: username };
        return this.http.post<User>(this.formatServiceUrl('/refreshPassword'), body);
    }

    mergeProfile(profile: IProfile): Observable<Profile>
    {
        return this._profileRepository.update(
            this.http.post<Profile>(
                this.formatServiceUrl('/merge'),
                profile instanceof Profile ? profile.toJson() : profile,
            ),
            this.profile as Profile,
        );
    }

    getProfile(): Observable<Profile>
    {
        return this._profileRepository.add(this.http.get<IProfile>('api/profile-service/profiles'));
    }

    signUp(email: string): Observable<User>
    {
        return this.http.post<User>(this.formatServiceUrl('/register'), {
            username: email,
        }, { observe: 'response' }).pipe(
            map((response) =>
            {
                this._lastResponse = response;
                return response.body as User;
            }),
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this.http.post('api/auth/unlock-session', credentials);
    }

    deleteProfile(username: string): Observable<any>
    {
        return this.http.delete(`${this.formatBaseUrl('/profile-service/admin/profile')}/?key=${username}`);
    }

    loadImage(profile: Nullable<Profile> = this._profile): void
    {

    }

    protected createInstanceFrom(json: Profile): Profile
    {
        return new Profile(json);
    }
}
