import { ISelectOption, ISelectOptions } from '../types';


export abstract class TreeSelectOptions<TValue,
    TObject extends object,
    TOption extends Required<ISelectOption<TValue, TObject>>>
    implements ISelectOptions<TValue, TObject, TOption>
{
    public readonly optionsTree = new Array<TOption>();

    protected constructor(
        public readonly values: Array<TObject>,
        public readonly options: Array<TOption>,
    )
    {
    }

    public abstract get valuesTree(): Array<TObject>;

    public async load(): Promise<Array<TOption>>
    {
        await this.loadValuesTree();

        const mapTree = (object: TObject): TOption =>
        {
            const option = this.mapOption(object);

            option.options = option.objectChildren.map(_object => mapTree(_object));

            return option;
        };

        this.optionsTree.push(...this.valuesTree.map<TOption>(object => mapTree(object)));
        this.options.push(...this.values.map<TOption>(_interest => this.mapOption(_interest)));

        return this.options as Array<TOption>;
    }

    public add(option: TOption): void
    {
        const parentOptions = new Array<TOption>();
        this.selectOption(option, true, parentOptions);

        for (const _option of [...parentOptions, option]) {
            if (this.values.includes(_option.objectValue) || !_option.canSelect()) {
                continue;
            }
            this.values.push(_option.objectValue);
            this.options.push(this.mapOption(_option.objectValue));
        }
    }

    public remove(option: TOption): void
    {
        const index = this.values.findIndex(_interest => _interest === option.objectValue);

        if (index >= 0) {
            this.values.splice(index, 1);
            this.options.splice(index, 1);

            const parentOptions = new Array<TOption>();
            this.selectOption(option, false, parentOptions);

            for (const childOption of option.options) {
                if ((childOption as TOption).isSelected()) {
                    this.remove(childOption as TOption);
                }
            }

            for (const _option of parentOptions.reverse()) {
                if (!_option.canDeselect()) {
                    continue;
                }
                this.remove(_option);
            }
        }
    }

    public findOption(object: TObject): TOption
    {
        const searchTree = (options: Array<TOption>): TOption =>
            options.reduce<TOption>((current, next) =>
            {
                if (current) {
                    return current;
                }

                if (next.objectValue === object) {
                    return next as TOption;
                }

                return searchTree(next.options as Array<TOption>);
            }, null as never);

        return searchTree(this.optionsTree as Array<TOption>);
    }

    private selectOption(option: TOption, selected: boolean, parents = new Array<TOption>()): void
    {
        const find = (_option: TOption, _parents: Array<TOption>): boolean =>
        {
            if (_option.name !== option.name) {
                const childParents = [..._parents, _option];
                const found        = _option.options?.some(childOption => find(
                    childOption as TOption,
                    childParents,
                ));
                if (found) {
                    _parents.length = 0;
                    _parents.push(...childParents);
                }

                // noinspection PointlessBooleanExpressionJS
                return !!found;
            }

            return true;
        };
        this.optionsTree.forEach((_interest) =>
        {
            const _parents = [...parents];
            if (find(_interest, _parents)) {
                parents.push(..._parents);
            }
        });

        if (option.canSelect()) {
            option.select(selected);
        }

        if (selected) {
            parents.forEach((parent) =>
            {
                if (parent.canSelect()) {
                    parent.select(selected);
                }
            });
        }
    }

    protected abstract loadValuesTree(): Promise<void>;

    protected abstract mapOption(objectValue: TObject): TOption;
}
