import { Directive, Input } from '@angular/core';
import { Nullable } from 'simplytyped';


@Directive({
    selector: 'img[src]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[src]'  : 'checkPath(src)',
        '(error)': 'onError()',
    },
})
export class DefaultImageDirective
{
    @Input()
    src: Nullable<string>;

    @Input()
    public defaultImg: string = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

    public onError(): void
    {
        this.src = this.defaultImg;
    }

    public checkPath(src: string): string
    {
        return src ? src : this.defaultImg;
    }
}
